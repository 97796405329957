import React, { useEffect, useState } from 'react';
import './McqQuestionsDisplay.css';
import { getPrintableExamAdmin } from '../../axiosLib/services/exam/admin';
import { get } from 'lodash';
import { Button, Card, CardContent, Container, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const McqQuestionsDisplay = () => {
  const navigate = useNavigate();

  const [examData, setExamData] = useState({});

  const [examParams, setExamParams] = useState({});

  const [renderedPages, setRenderedPages] = useState('<div></div>');

  const handlePrint = () => {
    window.print();
  };

  const handleChange = (fieldName, event) => {
    setExamParams({ ...examParams, [fieldName]: event.target.value });

    console.log(examParams);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const examId = urlParams.get('examId');

    let tempExamParam = {
      examId: examId,
      questionNumbers: urlParams.get('questionNumbers'),
      examSet: urlParams.get('set') ?? 'A',
      fontSize: urlParams.get('fontSize') ?? '12px',
      firstPageTopPadding: urlParams.get('firstPageTopPadding') ?? '200px',
      perQuestionGap: urlParams.get('perQuestionGap') ?? '2px',
      imageHeight: urlParams.get('imageHeight') ?? '15px',
    };

    setExamParams(tempExamParam);

    const data = {
      examId: examId,
      examSet: tempExamParam.examSet,
    };

    getPrintableExamAdmin(data, (err, data) => {
      if (!err) {
        let examData = get(data, 'examDetail', {});
        let totalQuestions = Number(get(examData, 'mcqContent', []).length);

        // let pagesContain = [0, 12, 24, 38, 50];
        console.log(tempExamParam.questionNumbers.split(':').map(Number));
        // let pagesContain = [0, 10, 20, 41, 56, 72, 87, 93, 100];
        let pagesContain = tempExamParam.questionNumbers.split(':').map(Number);
        pagesContain.unshift(0);

        let tSerialized = [];
        let questionCount = 1;

        for (let i = 0; i < pagesContain.length - 1; i++) {
          let content = get(examData, 'mcqContent', []).slice(pagesContain[i], pagesContain[i + 1]);
          let tempContent = [];

          for (let j = 0; j < content.length; j++) {
            content[j].questionNumber = questionCount;
            questionCount++;
            tempContent.push(content[j]);
          }

          tSerialized.push(tempContent);
        }
        //
        setExamData(get(data, 'examDetail', {}));

        // max-height: 15px; max-width: 500px; object-fit: cover;

        let renders = [];
        for (let i = 0; i < tSerialized.length; i = i + 2) {
          renders.push(
            <div className="print-div" style={{ fontSize: tempExamParam.fontSize }}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="single-column" style={{ width: '100%' }}>
                    {tSerialized[i].map((question, questionIndex) => (
                      <div
                        key={question._id}
                        className="question"
                        style={{ paddingBottom: tempExamParam.perQuestionGap }}
                      >
                        <table style={{ width: '100%', marginBottom: '1px' }}>
                          <tbody>
                            <tr>
                              <td colSpan="2">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: `<strong>${get(
                                      question,
                                      'questionNumber',
                                      ''
                                    )}.</strong> ${question.question
                                      .replace(/<p>/g, '<span>')
                                      .replace(/<\/p>/g, '</span>')
                                      .replace(
                                        /<img([^>]*)\/?>/g,
                                        '<span style="display:inline-block; margin:0 2px;">' +
                                          '<img style="max-height: ' +
                                          tempExamParam.imageHeight +
                                          '; max-width: 1000px; object-fit: cover;" $1 /></span>'
                                      )}`,
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              {question.options.slice(0, 2).map((option, optionIndex) => (
                                <td key={option._id} className="option-cell" style={{ padding: '1px 1px' }}>
                                  <span
                                    className="option-title"
                                    dangerouslySetInnerHTML={{
                                      __html: `<strong>${String.fromCharCode(65 + optionIndex)}:</strong> ${option.title
                                        .replace(/<p>/g, '<span>')
                                        .replace(/<\/p>/g, '</span>')
                                        .replace(
                                          /<img([^>]*)\/?>/g,
                                          '<span style="display:inline-block; margin:0 2px;">' +
                                            '<img style="max-height: ' +
                                            tempExamParam.imageHeight +
                                            '; max-width: 1000px; object-fit: cover;" $1 /></span>'
                                        )}`,
                                    }}
                                  />
                                </td>
                              ))}
                            </tr>

                            <tr>
                              {question.options.slice(2, 4).map((option, optionIndex) => (
                                <td key={option._id} className="option-cell" style={{ padding: '1px 1px' }}>
                                  <span
                                    className="option-title"
                                    dangerouslySetInnerHTML={{
                                      __html: `<strong>${String.fromCharCode(67 + optionIndex)}:</strong> ${option.title
                                        .replace(/<p>/g, '<span>')
                                        .replace(/<\/p>/g, '</span>')
                                        .replace(
                                          /<img([^>]*)\/?>/g,
                                          '<span style="display:inline-block; margin:0 2px;">' +
                                            '<img style="max-height: ' +
                                            tempExamParam.imageHeight +
                                            '; max-width: 1000px; object-fit: cover;" $1 /></span>'
                                        )}`,
                                    }}
                                  />
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="single-column" style={{ width: '100%' }}>
                    {tSerialized[i + 1].map((question, questionIndex) => (
                      <div
                        key={question._id}
                        className="question"
                        style={{ paddingBottom: tempExamParam.perQuestionGap }}
                      >
                        <table style={{ width: '100%', marginBottom: '1px' }}>
                          <tbody>
                            <tr>
                              <td colSpan="2">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: `<strong>${get(
                                      question,
                                      'questionNumber',
                                      ''
                                    )}.</strong> ${question.question
                                      .replace(/<p>/g, '<span>')
                                      .replace(/<\/p>/g, '</span>')
                                      .replace(
                                        /<img([^>]*)\/?>/g,
                                        '<span style="display:inline-block; margin:0 2px;">' +
                                          '<img style="max-height: ' +
                                          tempExamParam.imageHeight +
                                          '; max-width: 1000px; object-fit: cover;" $1 /></span>'
                                      )}`,
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              {question.options.slice(0, 2).map((option, optionIndex) => (
                                <td key={option._id} className="option-cell" style={{ padding: '1px 1px' }}>
                                  <span
                                    className="option-title"
                                    dangerouslySetInnerHTML={{
                                      __html: `<strong>${String.fromCharCode(65 + optionIndex)}:</strong> ${option.title
                                        .replace(/<p>/g, '<span>')
                                        .replace(/<\/p>/g, '</span>')
                                        .replace(
                                          /<img([^>]*)\/?>/g,
                                          '<span style="display:inline-block; margin:0 2px;">' +
                                            '<img style="max-height: ' +
                                            tempExamParam.imageHeight +
                                            '; max-width: 1000px; object-fit: cover;" $1 /></span>'
                                        )}`,
                                    }}
                                  />
                                </td>
                              ))}
                            </tr>

                            <tr>
                              {question.options.slice(2, 4).map((option, optionIndex) => (
                                <td key={option._id} className="option-cell" style={{ padding: '1px 1px' }}>
                                  <span
                                    className="option-title"
                                    dangerouslySetInnerHTML={{
                                      __html: `<strong>${String.fromCharCode(67 + optionIndex)}:</strong> ${option.title
                                        .replace(/<p>/g, '<span>')
                                        .replace(/<\/p>/g, '</span>')
                                        .replace(
                                          /<img([^>]*)\/?>/g,
                                          '<span style="display:inline-block; margin:0 2px;">' +
                                            '<img style="max-height: ' +
                                            tempExamParam.imageHeight +
                                            '; max-width: 1000px; object-fit: cover;" $1 /></span>'
                                        )}`,
                                    }}
                                  />
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          );
        }

        setRenderedPages(
          <div className="mcq-container" style={{ fontSize: tempExamParam.fontSize }}>
            {/*<div className="image-container">*/}
            {/*  <img src="https://www.drasifbatch.com/img/asif/logo.png" alt="Top Image" className="top-image" />*/}
            {/*</div>*/}

            {/*<div*/}
            {/*  className="row"*/}
            {/*  style={{*/}
            {/*    display: 'flex',*/}
            {/*    justifyContent: 'center', // Center horizontally*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <div*/}
            {/*    style={{*/}
            {/*      width: '750px',*/}
            {/*      height: 'auto',*/}
            {/*      border: '1px solid black',*/}
            {/*      backgroundColor: 'lightgray',*/}
            {/*      padding: '3px',*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    /!*<div className="row">*!/*/}
            {/*    /!*  <h5 style={{ textAlign: 'center' }}>Medi Inn</h5>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    <div className="row">*/}
            {/*      <div className="col">*/}
            {/*        <h6 style={{ textAlign: 'center' }}>Exam: {get(examData, 'name', 'A')}</h6>*/}
            {/*      </div>*/}
            {/*      <div className="col">*/}
            {/*        <h6 style={{ textAlign: 'center' }}>Set: {questionSet}</h6>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div style={{ marginTop: tempExamParam.firstPageTopPadding }}>{renders}</div>
          </div>
        );
      } else {
        // navigate('/login', { replace: true });

        alert('Solution is not available yet !');
      }
    });
  }, []);

  // Sample questions stored as HTML strings
  return (
    <div>
      <div className={'print-button'} style={{ textAlign: 'center' }}>
        <div>
          <Container maxWidth="xl">
            <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
              <CardContent
                style={{
                  backgroundColor: 'beige',
                  color: '#800000',
                  fontWeight: 'bold',
                  fontSize: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Exam: {get(examData, 'name', '')}
              </CardContent>
            </Card>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                id="questionNumbers-x"
                name="questionNumbers"
                label="MCQ Per Page"
                value={get(examParams, 'questionNumbers', '')}
                style={{ width: '100%' }}
                onChange={(e) => {
                  handleChange('questionNumbers', e);
                }}
                required
              />

              <TextField
                id="examSet-X"
                name="examSet"
                label="Exam Set To Print"
                value={get(examParams, 'examSet', '')}
                style={{ width: '20%' }}
                onChange={(e) => {
                  handleChange('examSet', e);
                }}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} style={{paddingTop: '25px'}}>
              <TextField
                name="fontSize"
                label="Font Size"
                value={get(examParams, 'fontSize', '0px')}
                style={{ width: '20%' }}
                onChange={(e) => {
                  handleChange('fontSize', e);
                }}
                required
              />

              <TextField
                name="firstPageTopPadding"
                label="1st Page Top Spacing"
                value={get(examParams, 'firstPageTopPadding', '0px')}
                style={{ width: '30%' }}
                onChange={(e) => {
                  handleChange('firstPageTopPadding', e);
                }}
                required
              />

              <TextField
                name="perQuestionGap"
                label="Per Question Gap"
                value={get(examParams, 'perQuestionGap', '0px')}
                style={{ width: '30%' }}
                onChange={(e) => {
                  handleChange('perQuestionGap', e);
                }}
                required
              />

              <TextField
                name="imageHeight"
                label="Equation Height"
                value={get(examParams, 'imageHeight', '0px')}
                style={{ width: '30%' }}
                onChange={(e) => {
                  handleChange('imageHeight', e);
                }}
                required
              />
            </Stack>
          </Container>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15px' }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button
              style={{ backgroundColor: 'darkgreen', color: 'white', padding: '10px 40px', marginRight: '10px' }}
              onClick={() => {
                let questionNumbers = examParams.questionNumbers.split(':').map(Number);
                let questionNumberStr = questionNumbers
                  .reduce((acc, curr, index, arr) => {
                    if (index % 2 === 0) {
                      acc.push(`${arr[index]}:${arr[index + 1]}`);
                    }
                    return acc;
                  }, [])
                  .join('    :    ');

                const url = new URL(window.location.href); // Get current URL
                url.searchParams.set('examId', examParams.examId); // Add or update parameter
                url.searchParams.set('set', examParams.examSet); // Add or update parameter
                url.searchParams.set('questionNumbers', questionNumberStr); // Add or update parameter
                url.searchParams.set('fontSize', examParams.fontSize); // Add or update parameter
                url.searchParams.set('firstPageTopPadding', examParams.firstPageTopPadding); // Add or update parameter
                url.searchParams.set('perQuestionGap', examParams.perQuestionGap); // Add or update parameter
                url.searchParams.set('imageHeight', examParams.imageHeight); // Add or update parameter
                // console.log(url.toString()); // Get the updated URL

                window.location.href = url.toString();
              }}
            >
              Reload Page
            </Button>

            {/*<Button*/}
            {/*  style={{ backgroundColor: '#800000', color: 'white', padding: '10px 40px', marginRight: '10px' }}*/}
            {/*  onClick={() => {*/}
            {/*    // window.print();*/}

            {/*    // Store the original title*/}
            {/*    const originalTitle = document.title;*/}
            {/*    // Set the desired title for the PDF filename*/}
            {/*    document.title = 'SET- ' + get(examParams, 'examSet', '') + ' - ' + get(examData, 'name', '');*/}
            {/*    // Trigger the print dialog*/}
            {/*    window.print();*/}
            {/*    // Restore the original title after printing*/}
            {/*    document.title = originalTitle;*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Print Exam*/}
            {/*</Button>*/}
          </Stack>
        </div>
      </div>

      <div className="print-container">{renderedPages}</div>
    </div>
  );
};

export default McqQuestionsDisplay;


// <div className="main-question-container">
//   <div className="row">
//     <div className="col-sm-6">
//       <div className="single-column" style={{ width: '100%' }}>
//         {firstColumn.map((question, questionIndex) => (
//           <div key={question._id} className="question">
//             <table style={{ width: '100%', marginBottom: '1px' }}>
//               <tbody>
//               <tr>
//                 <td colSpan="2">
//                           <span
//                             dangerouslySetInnerHTML={{
//                               __html: `<strong>${get(question, 'questionNumber', '')}.</strong> ${question.question
//                                 .replace(/<p>/g, '<span>')
//                                 .replace(/<\/p>/g, '</span>')
//                                 .replace(
//                                   /<img([^>]*)\/?>/g,
//                                   '<span style="display:inline-block; margin:0 2px;"><img$1 /></span>'
//                                 )}`,
//                             }}
//                           />
//                 </td>
//               </tr>
//               <tr>
//                 {question.options.slice(0, 2).map((option, optionIndex) => (
//                   <td key={option._id} className="option-cell" style={{ padding: '1px 1px' }}>
//                             <span
//                               className="option-title"
//                               dangerouslySetInnerHTML={{
//                                 __html: `<strong>${String.fromCharCode(65 + optionIndex)}:</strong> ${option.title
//                                   .replace(/<p>/g, '<span>')
//                                   .replace(/<\/p>/g, '</span>')
//                                   .replace(
//                                     /<img([^>]*)\/?>/g,
//                                     '<span style="display:inline-block; margin:0 2px;"><img$1 /></span>'
//                                   )}`,
//                               }}
//                             />
//                   </td>
//                 ))}
//               </tr>
//
//               <tr>
//                 {question.options.slice(2, 4).map((option, optionIndex) => (
//                   <td key={option._id} className="option-cell" style={{ padding: '1px 1px' }}>
//                             <span
//                               className="option-title"
//                               dangerouslySetInnerHTML={{
//                                 __html: `<strong>${String.fromCharCode(67 + optionIndex)}:</strong> ${option.title
//                                   .replace(/<p>/g, '<span>')
//                                   .replace(/<\/p>/g, '</span>')
//                                   .replace(
//                                     /<img([^>]*)\/?>/g,
//                                     '<span style="display:inline-block; margin:0 2px;"><img$1 /></span>'
//                                   )}`,
//                               }}
//                             />
//                   </td>
//                 ))}
//               </tr>
//               </tbody>
//             </table>
//           </div>
//         ))}
//       </div>
//     </div>
//
//     <div className="col-sm-6">
//       <div className="single-column" style={{ width: '100%' }}>
//         {secondColumn.map((question, questionIndex) => (
//           <div key={question._id} className="question">
//             <table style={{ width: '100%', marginBottom: '1px' }}>
//               <tbody>
//               <tr>
//                 <td colSpan="2">
//                           <span
//                             dangerouslySetInnerHTML={{
//                               __html: `<strong>${get(question, 'questionNumber', '')}.</strong> ${question.question
//                                 .replace(/<p>/g, '<span>')
//                                 .replace(/<\/p>/g, '</span>')
//                                 .replace(
//                                   /<img([^>]*)\/?>/g,
//                                   '<span style="display:inline-block; margin:0 2px;"><img$1 /></span>'
//                                 )}`,
//                             }}
//                           />
//                 </td>
//               </tr>
//               <tr>
//                 {question.options.slice(0, 2).map((option, optionIndex) => (
//                   <td key={option._id} className="option-cell" style={{ padding: '1px 1px' }}>
//                             <span
//                               className="option-title"
//                               dangerouslySetInnerHTML={{
//                                 __html: `<strong>${String.fromCharCode(65 + optionIndex)}:</strong> ${option.title
//                                   .replace(/<p>/g, '<span>')
//                                   .replace(/<\/p>/g, '</span>')
//                                   .replace(
//                                     /<img([^>]*)\/?>/g,
//                                     '<span style="display:inline-block; margin:0 2px;"><img$1 /></span>'
//                                   )}`,
//                               }}
//                             />
//                   </td>
//                 ))}
//               </tr>
//
//               <tr>
//                 {question.options.slice(2, 4).map((option, optionIndex) => (
//                   <td key={option._id} className="option-cell" style={{ padding: '1px 1px' }}>
//                             <span
//                               className="option-title"
//                               dangerouslySetInnerHTML={{
//                                 __html: `<strong>${String.fromCharCode(67 + optionIndex)}:</strong> ${option.title
//                                   .replace(/<p>/g, '<span>')
//                                   .replace(/<\/p>/g, '</span>')
//                                   .replace(
//                                     /<img([^>]*)\/?>/g,
//                                     '<span style="display:inline-block; margin:0 2px;"><img$1 /></span>'
//                                   )}`,
//                               }}
//                             />
//                   </td>
//                 ))}
//               </tr>
//               </tbody>
//             </table>
//           </div>
//         ))}
//       </div>
//     </div>
//   </div>
// </div>


// {totalSerialized.map((listOfElem, index, array) => (
//   <div className="main-question-container">
//     <div className="row">
//       <div className="col-sm-6">
//         <div className="single-column" style={{ width: '100%' }}>
//           {index < 7 && array[index].map((question, questionIndex) => (
//             <div key={question._id} className="question">
//               <table style={{ width: '100%', marginBottom: '1px' }}>
//                 <tbody>
//                 <tr>
//                   <td colSpan="2">
//                             <span
//                               dangerouslySetInnerHTML={{
//                                 __html: `<strong>${get(question, 'questionNumber', '')}.</strong> ${question.question
//                                   .replace(/<p>/g, '<span>')
//                                   .replace(/<\/p>/g, '</span>')
//                                   .replace(
//                                     /<img([^>]*)\/?>/g,
//                                     '<span style="display:inline-block; margin:0 2px;"><img$1 /></span>'
//                                   )}`,
//                               }}
//                             />
//                   </td>
//                 </tr>
//                 <tr>
//                   {question.options.slice(0, 2).map((option, optionIndex) => (
//                     <td key={option._id} className="option-cell" style={{ padding: '1px 1px' }}>
//                               <span
//                                 className="option-title"
//                                 dangerouslySetInnerHTML={{
//                                   __html: `<strong>${String.fromCharCode(65 + optionIndex)}:</strong> ${option.title
//                                     .replace(/<p>/g, '<span>')
//                                     .replace(/<\/p>/g, '</span>')
//                                     .replace(
//                                       /<img([^>]*)\/?>/g,
//                                       '<span style="display:inline-block; margin:0 2px;"><img$1 /></span>'
//                                     )}`,
//                                 }}
//                               />
//                     </td>
//                   ))}
//                 </tr>
//
//                 <tr>
//                   {question.options.slice(2, 4).map((option, optionIndex) => (
//                     <td key={option._id} className="option-cell" style={{ padding: '1px 1px' }}>
//                               <span
//                                 className="option-title"
//                                 dangerouslySetInnerHTML={{
//                                   __html: `<strong>${String.fromCharCode(67 + optionIndex)}:</strong> ${option.title
//                                     .replace(/<p>/g, '<span>')
//                                     .replace(/<\/p>/g, '</span>')
//                                     .replace(
//                                       /<img([^>]*)\/?>/g,
//                                       '<span style="display:inline-block; margin:0 2px;"><img$1 /></span>'
//                                     )}`,
//                                 }}
//                               />
//                     </td>
//                   ))}
//                 </tr>
//                 </tbody>
//               </table>
//             </div>
//           ))}
//         </div>
//       </div>
//
//       <div className="col-sm-6">
//         <div className="single-column" style={{ width: '100%' }}>
//           {index < 7 && array[index+1].map((question, questionIndex) => (
//             <div key={question._id} className="question">
//               <table style={{ width: '100%', marginBottom: '1px' }}>
//                 <tbody>
//                 <tr>
//                   <td colSpan="2">
//                             <span
//                               dangerouslySetInnerHTML={{
//                                 __html: `<strong>${get(question, 'questionNumber', '')}.</strong> ${question.question
//                                   .replace(/<p>/g, '<span>')
//                                   .replace(/<\/p>/g, '</span>')
//                                   .replace(
//                                     /<img([^>]*)\/?>/g,
//                                     '<span style="display:inline-block; margin:0 2px;"><img$1 /></span>'
//                                   )}`,
//                               }}
//                             />
//                   </td>
//                 </tr>
//                 <tr>
//                   {question.options.slice(0, 2).map((option, optionIndex) => (
//                     <td key={option._id} className="option-cell" style={{ padding: '1px 1px' }}>
//                               <span
//                                 className="option-title"
//                                 dangerouslySetInnerHTML={{
//                                   __html: `<strong>${String.fromCharCode(65 + optionIndex)}:</strong> ${option.title
//                                     .replace(/<p>/g, '<span>')
//                                     .replace(/<\/p>/g, '</span>')
//                                     .replace(
//                                       /<img([^>]*)\/?>/g,
//                                       '<span style="display:inline-block; margin:0 2px;"><img$1 /></span>'
//                                     )}`,
//                                 }}
//                               />
//                     </td>
//                   ))}
//                 </tr>
//
//                 <tr>
//                   {question.options.slice(2, 4).map((option, optionIndex) => (
//                     <td key={option._id} className="option-cell" style={{ padding: '1px 1px' }}>
//                               <span
//                                 className="option-title"
//                                 dangerouslySetInnerHTML={{
//                                   __html: `<strong>${String.fromCharCode(67 + optionIndex)}:</strong> ${option.title
//                                     .replace(/<p>/g, '<span>')
//                                     .replace(/<\/p>/g, '</span>')
//                                     .replace(
//                                       /<img([^>]*)\/?>/g,
//                                       '<span style="display:inline-block; margin:0 2px;"><img$1 /></span>'
//                                     )}`,
//                                 }}
//                               />
//                     </td>
//                   ))}
//                 </tr>
//                 </tbody>
//               </table>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   </div>
// ))}
