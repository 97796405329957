import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import get from 'lodash';
import Iconify from 'src/components/Iconify';
import { Link as RouterLink } from 'react-router-dom';

const drawerWidth = 240;
const navItems = [
  {
    text: 'Dashboard',
    link: localStorage.getItem('type')
      ? localStorage.getItem('type') == 'admin'
        ? '/dashboard/app'
        : '/user/currentExams'
      : '/login',
  },
  //   {
  //       text: 'Home',
  //       link: '#'
  //   },
  //   {
  //     text: 'Course',
  //     link: '#'
  // }
];

const primaryNavItems = [
  {
    text: localStorage.getItem('type') ? 'Login' : 'Login',
    link: localStorage.getItem('type')
      ? localStorage.getItem('type') == 'admin'
        ? '/dashboard/app'
        : '/user/currentExams'
      : '/login',
  },
  //   {
  //       text: 'Home',
  //       link: '#'
  //   },
  //   {
  //     text: 'Course',
  //     link: '#'
  // }
];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2, ml: '40%' }}>
        <Avatar src="/static/mock-images/avatars/avatar_default.jpg" alt="photoURL" />
      </Typography>
      <Divider />

      <List>
        {navItems.map((item) => (
          <ListItem key={get(item, 'text')} disablePadding>
            <ListItemButton sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                key={item.text}
                sx={{ fontSize: '20px', marginRight: '5px' }}
                component={RouterLink}
                to={item.link}
              >
                {item.text}
              </Button>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', fontSize: '50px' }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ backgroundColor: '#0c366d', minHeight: '10%', display: 'flex', justifyContent: 'center' }}
      >
        <Toolbar>
          {/* <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { sm: 'block' } }}
          >
            MUI
          </Typography> */}
          <Typography component="div" sx={{ flexGrow: 0.9, display: { sm: 'block' }, ml: '5%' }}>
            <Box
              component="img"
              src={'/img/asif/logo.png'}
              sx={{ width: 85, height: 60 }}
              alt="Medi Inn by Dr. Asif & Kabir Sizer"
            />
          </Typography>
          <Button
            key={'nav-login-btn'}
            sx={{ fontSize: '20px', marginRight: '20px', backgroundColor: 'white' }}
            component={RouterLink}
            to={primaryNavItems[0].link}
          >
            {primaryNavItems[0].text}
          </Button>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button
                key={item.text}
                sx={{ color: 'white', fontSize: '20px', marginRight: '5px' }}
                component={RouterLink}
                to={item.link}
              >
                {item.text}
              </Button>
            ))}
          </Box>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <Iconify icon="eva:menu-2-fill" color="white" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
