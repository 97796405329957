import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import config from '../../util/config';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <Page title="Landing">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h2" paragraph>
            WELCOME TO
          </Typography>

          <Typography variant="h4" paragraph>
            {config.VENDOR_1}
          </Typography>
          <Typography variant="h2" paragraph>
            &
          </Typography>
          <Typography variant="h4" paragraph>
            {config.VENDOR_2}
          </Typography>

          <Box component="img" src={config.IMAGE_BASE_URL} sx={{ mx: 'auto', my: { xs: 2, sm: 1 } }} />

          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/user/login"
              style={{ maxWidth: '80%', maxHeight: '50%', minWidth: '50%', minHeight: '50%', fontSize: '20px' }}
            >
              Student Login
            </Button>

            <Button
              variant="contained"
              component={RouterLink}
              to="/admin/login"
              style={{
                maxWidth: '80%',
                maxHeight: '50%',
                minWidth: '50%',
                minHeight: '50%',
                marginLeft: '20px',
                fontSize: '20px',
              }}
            >
              Admin Login
            </Button>
          </Stack>

          {/* <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button> */}
        </ContentStyle>
      </Container>
    </Page>
  );
}
