// @mui
import { styled } from '@mui/material/styles';
import { Card, Container, Paper, Stack } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
// sections
import UserOwnRegisterForPublicExam from 'src/sections/auth/register/UserOwnRegisterForPublicExam';

// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  // maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <Page title="Login">
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <HeaderStyle>{/* <Logo /> */}</HeaderStyle>
        <SectionStyle>
          <img src="/img/asif/publicExam.png" alt="login" />
        </SectionStyle>

        <Container>
          <ContentStyle>
            <UserOwnRegisterForPublicExam />

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
              {' '}
            </Stack>
          </ContentStyle>
        </Container>
      </Stack>
    </Page>
  );
}
