import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// APIS
import {
  enrollUser,
  getAllInstituteChangeRequestedUsers,
  getAllRemovedUsers,
  getAllUnverifiedUsers,
  restoreUser,
  verifyUser,
} from 'src/axiosLib/services/user/admin';
// material
import {
  Avatar,
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import moment from 'moment';
import { AccountTreeRounded, Verified } from '@mui/icons-material';
import { getAllBatches } from 'src/axiosLib/services/batch/admin';
import { getAllBranches } from 'src/axiosLib/services/branch/admin';
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'guardianSMS', label: 'Guardian SMS', alignRight: false },
  { id: 'address', label: 'College', alignRight: false },
  // { id: 'accessControl', label: 'Access Control', alignRight: false },
  { id: 'valid_till', label: 'Validity', alignRight: false },
  { id: 'profile', label: 'Verify Student', alignRight: false },
];

const TABLE_HEAD_BLOCKED = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'guardianSMS', label: 'Guardian SMS', alignRight: false },
  { id: 'address', label: 'College', alignRight: false },
  // { id: 'accessControl', label: 'Access Control', alignRight: false },
  { id: 'valid_till', label: 'Validity', alignRight: false },
  { id: 'profile', label: 'Verify Student', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const navigate = useNavigate();

  const [grantModal, setGrantModal] = useState(false);

  const [batchList, setBatchList] = useState([]);

  const [branchList, setBranchList] = useState([]);

  const [grantData, setGrantData] = useState();

  const [page, setPage] = useState(0);

  const [pageBlocked, setPageBlocked] = useState(0);

  const [pageNumberInstitute, setPageNumberInstitute] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [rowsPerPageBlocked, setRowsPerPageBlocked] = useState(10);

  const [rowsPerPageInstituteChange, setRowsPerPageInstituteChange] = useState(10);

  const [userList, setUserList] = useState([]);

  const [userCount, setUserCount] = useState(0);

  const [userListBlocked, setUserListBlocked] = useState([]);

  const [userCountBlocked, setUserCountBlocked] = useState(0);

  const [userListInstitutionChange, setUserListInstitutionChange] = useState([]);

  const [userCountInstitutionChange, setUserCountInstitutionChange] = useState(0);

  const grantStudent = () => {
    grantData.batchId = grantData.batchId.split(' ');
    grantData.branchId = grantData.branchId.split(' ');

    const id = grantData._id;

    enrollUser(id, grantData, (err, data) => {
      if (!err) {
        verifyUser(id, (err, data) => {
          if (!err) {
          } else {
            console.log(err);
          }
        });
      }
    });

    // window.location.reload(false);

    navigate('/dashboard/register_student/?std=' + id, { replace: true });

    setGrantModal(false);
    setGrantData({});
  };

  const grantModalHandler = (row) => {
    const temp = {
      _id: get(row, '_id', ''),
      guardianSMS: get(row, 'guardianSMS', ''),
      username: get(row, 'username', ''),
    };
    setGrantData(temp);

    setGrantModal(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChange = (fieldName, event) => {
    // console.log(event.target.value);
    setGrantData({ ...grantData, [fieldName]: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageBlocked = (event, newPage) => {
    setPageBlocked(newPage);
  };

  const handleChangePageInstituteChange = (event, newPage) => {
    setPageNumberInstitute(newPage);
  };

  const handleChangeRowsPerPageInstituteRequest = (event) => {
    setRowsPerPageInstituteChange(event.target.value);
    setPageNumberInstitute(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChangeRowsPerPageBlocked = (event) => {
    setRowsPerPageBlocked(event.target.value);
    setPageBlocked(0);
  };

  const restoreBlockedUser = (id) => {
    restoreUser(id, (error, data) => {
      if (!error) {
        alert('Process Succesful');
      } else {
        alert('Process FAILED');
      }

      window.location.reload(false);
    });
  };

  const getBatches = () => {
    try {
      getAllBatches(1000, 0, (error, data) => {
        if (!error) {
          // console.log(data);
          setBatchList(get(data, 'batches', []));
          setBatchCount(get(data, 'size', 0));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getBranches = () => {
    try {
      getAllBranches(1000, 0, (error, data) => {
        if (!error) {
          // console.log(data);
          setBranchList(get(data, 'branches', []));
          setBranchCount(get(data, 'size', 0));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getUnverifiedUsers = (rowsPerPage, page) => {
    try {
      getAllUnverifiedUsers(rowsPerPage, page, (error, data) => {
        if (!error) {
          // console.log(data);
          setUserList(get(data, 'user', []));
          setUserCount(get(data, 'total', 0));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getBlockedUsers = (rowsPerPageBlocked, pageBlocked) => {
    try {
      getAllRemovedUsers(rowsPerPageBlocked, pageBlocked, (error, data) => {
        if (!error) {
          // console.log(data);
          setUserListBlocked(get(data, 'users', []));
          setUserCountBlocked(get(data, 'size', 0));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getInstituteChangedUsers = (rowsPerPageInstituteChange, pageNumberInstitute) => {
    try {
      getAllInstituteChangeRequestedUsers(rowsPerPageInstituteChange, pageNumberInstitute, (error, data) => {
        if (!error) {
          // console.log(data);
          setUserListInstitutionChange(get(data, 'users', []));
          setUserCountInstitutionChange(get(data, 'size', 0));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);
  const filteredUsersInstitute = applySortFilter(userListInstitutionChange, getComparator(order, orderBy), filterName);
  const filteredUsersBlocked = applySortFilter(userListBlocked, getComparator(order, orderBy), filterName);

  useEffect(() => {
    getBlockedUsers(rowsPerPageBlocked, pageBlocked);
    getUnverifiedUsers(rowsPerPage, page);
    getInstituteChangedUsers(rowsPerPageInstituteChange, pageNumberInstitute);
    getBranches();
    getBatches();
  }, [filterName, page, rowsPerPage, rowsPerPageBlocked, pageBlocked]);

  return (
    <Page title="User">
      <Container>
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#06402b', backgroundColor: 'white' }}
            >
              Institute Change Requests
            </Typography>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsersInstitute.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="normal">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={get(row, 'username', '')} src={get(row, 'avatar', '')} />
                            <Typography variant="subtitle2" noWrap>
                              {get(row, 'username', '')}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left"> {get(row, 'phone', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'guardianSMS', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'address', '')}</TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <TextField
                              id="date"
                              label="account valid till"
                              type="date"
                              defaultValue={moment(get(row, 'validTill', null)).format('yyyy-MM-DD')}
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          }
                        </TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to={'/dashboard/register_student/?std=' + get(row, '_id')}
                            >
                              VIEW
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={userCountInstitutionChange}
            rowsPerPage={rowsPerPageInstituteChange}
            page={pageNumberInstitute}
            onPageChange={handleChangePageInstituteChange}
            onRowsPerPageChange={handleChangeRowsPerPageInstituteRequest}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              Unverified Student List
            </Typography>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={userList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="normal">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={get(row, 'username', '')} src={get(row, 'avatar', '')} />
                            <Typography variant="subtitle2" noWrap>
                              {get(row, 'username', '')}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left"> {get(row, 'phone', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'guardianSMS', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'address', '')}</TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <TextField
                              id="date"
                              label="account valid till"
                              type="date"
                              defaultValue={moment(get(row, 'validTill', null)).format('yyyy-MM-DD')}
                              onChange={() => {
                                console.log('changing date');
                              }}
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          }
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              onClick={() => grantModalHandler(row)}
                              variant="contained"
                              // onClick={() => this.props.onBlock(i, user._id)}
                              style={{ color: '#880ED4', backgroundColor: 'white' }}
                            >
                              <Verified style={{ color: '#880ED4', marginRight: 5 }} />
                              GRANT
                            </Button>
                          }
                        </TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to={'/dashboard/register_student/?std=' + get(row, '_id')}
                            >
                              VIEW
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={userCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              Blocked Student List
            </Typography>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_BLOCKED}
                  rowCount={userListBlocked.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsersBlocked.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="normal">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={get(row, 'username', '')} src={get(row, 'avatar', '')} />
                            <Typography variant="subtitle2" noWrap>
                              {get(row, 'username', '')}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left"> {get(row, 'phone', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'guardianSMS', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'address', '')}</TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <TextField
                              id="date"
                              label="account valid till"
                              type="date"
                              defaultValue={moment(get(row, 'validTill', null)).format('yyyy-MM-DD')}
                              onChange={() => {
                                console.log('changing date');
                              }}
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          }
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              onClick={(e) => {
                                restoreBlockedUser(get(row, '_id', ''));
                              }}
                              style={{ color: '#880ED4', backgroundColor: 'white' }}
                            >
                              <AccountTreeRounded style={{ color: '#880ED4', marginRight: 5 }} />
                              UNBLOCK
                            </Button>
                          }
                        </TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to={'/dashboard/register_student/?std=' + get(row, '_id')}
                            >
                              VIEW
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={userCountBlocked}
            rowsPerPage={rowsPerPageBlocked}
            page={pageBlocked}
            onPageChange={handleChangePageBlocked}
            onRowsPerPageChange={handleChangeRowsPerPageBlocked}
          />
        </Card>

        {/* BATCH */}
        <div>
          <Dialog open={grantModal} onClose={() => setGrantModal(false)}>
            <DialogTitle>Grant Access</DialogTitle>
            <DialogContent>
              {/* <TextField name="batchName" label="Batch Name" style={{width : "100%",  marginTop : 20}} required/> */}

              <FormControl fullWidth required style={{ width: '100%', marginTop: 20 }}>
                <InputLabel id="sessiom-label">Branch Name</InputLabel>
                <Select
                  id="branch-label"
                  value={get(grantData, 'branchId', '')}
                  label="Batch Name"
                  onChange={(e) => {
                    handleChange('branchId', e);
                  }}
                >
                  {branchList.map((batch) => (
                    <MenuItem key={batch.branchName} value={batch._id}>
                      {batch.branchName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth required style={{ width: '100%', marginTop: 20 }}>
                <InputLabel id="sessiom-label">Batch Name</InputLabel>
                <Select
                  id="session-label"
                  value={get(grantData, 'batchId', '')}
                  label="Batch Name"
                  onChange={(e) => {
                    handleChange('batchId', e);
                  }}
                >
                  {batchList.map((batch) => (
                    <MenuItem key={batch.batchName} value={batch._id}>
                      {batch.batchName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                name="guardianSMS"
                label="Guardian SMS"
                style={{ width: '100%', marginTop: 20 }}
                required
                value={get(grantData, 'guardianSMS', '')}
                onChange={(e) => {
                  handleChange('guardianSMS', e);
                }}
              />

              <TextField
                name="roll"
                label="Roll Number"
                style={{ width: '100%', marginTop: 20 }}
                required
                onChange={(e) => {
                  handleChange('roll', e);
                }}
              />
            </DialogContent>

            {/* {/* <DialogTitle>Student Name</DialogTitle> */}
            <DialogTitle style={{ color: '#880ED4' }}>{get(grantData, 'username', '')}</DialogTitle>

            <DialogActions>
              <Button
                variant="contained"
                onClick={() => grantStudent()}
                style={{ color: 'white', backgroundColor: 'blue' }}
              >
                GRANT & SEND SMS
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </Page>
  );
}
