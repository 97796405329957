import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
// components
import { FormProvider } from '../../../components/hook-form';

import moment from 'moment';
import { get } from 'lodash';
import { signupUsername } from 'src/axiosLib/services/user';
import { getAllPublicInstitutions } from '../../../axiosLib/services/institution/user';

// ----------------------------------------------------------------------

export default function UserOwnRegister() {
  const navigate = useNavigate();

  const [studentInfo, setStudentInfo] = useState({ validTill: moment().format('DD/MM/YYYY'), session: 2024 });

  const [allInstitutions, setAllInstitutions] = useState([]);

  useEffect(() => {
    getAllPublicInstitutions((error, data) => {
      if (!error) {
        setAllInstitutions(data);
      }
    });
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const handleRegister = () => {
    signupUsername(studentInfo, (err, data) => {
      if (!err) {
        alert('Successfully Registered');
      } else {
        let errorMessage = '';

        for (const errId in err) {
          errorMessage = errorMessage + ', ' + err[errId];
        }

        errorMessage = errorMessage.slice(2);
        alert(errorMessage);
      }
    });

    // console.log(studentInfo)
  };

  const handleChange = (fieldName, event) => {
    // console.log(event.target.value);
    if (fieldName === 'validTill') {
      setStudentInfo({ ...studentInfo, [fieldName]: moment(event.target.value).format('DD/MM/YYYY') });
    } else if (fieldName === 'institutionId') {
      let data = allInstitutions.filter((item) => item.institutionName === event.target.value)[0];

      setStudentInfo({ ...studentInfo, [fieldName]: data });

      // console.log(studentInfo)
    } else {
      setStudentInfo({ ...studentInfo, [fieldName]: event.target.value });
    }
  };

  return (
    <div>
      <FormProvider>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="firstName"
              label="First name"
              value={get(studentInfo, 'firstname', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('firstname', e);
              }}
              required
            />
            <TextField
              name="lastName"
              label="Last name"
              value={get(studentInfo, 'lastname', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('lastname', e);
              }}
              required
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="username"
              label="Full Name"
              value={get(studentInfo, 'username', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('username', e);
              }}
              required
            />
            <TextField
              name="password"
              label="Password"
              type="password"
              value={get(studentInfo, 'password', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('password', e);
              }}
              required
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="phone"
              label="Student Phone"
              value={get(studentInfo, 'phone', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('phone', e);
              }}
              required
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <TextField
              name="guardianSms"
              label="Guardian SMS"
              value={get(studentInfo, 'guardianSMS', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('guardianSMS', e);
              }}
              required
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="address"
              label="College & District"
              value={get(studentInfo, 'address', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('address', e);
              }}
              required
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl fullWidth required style={{ width: '100%' }}>
              <InputLabel id="sessiom-label">Preferred Branch</InputLabel>
              <Select
                id="session-label"
                required
                label="Preferred Batch"
                onChange={(e) => {
                  handleChange('institutionId', e);
                }}
              >
                {allInstitutions.map((institution) => (
                  <MenuItem key={institution._id} value={institution.institutionName}>
                    {institution.institutionName +" # "+ institution.mobile}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Button variant="contained" style={{ background: 'blue' }} onClick={() => handleRegister()}>
            REGISTER
          </Button>
        </Stack>
      </FormProvider>
    </div>
  );
}
