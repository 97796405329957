import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
// sections
import UserLoginForm from 'src/sections/auth/login/LoginFormUser';

import { get } from 'lodash';
import { resetPassword } from 'src/axiosLib/services/user';
import { useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function UserLogin() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  const [subjectModal, setSubjectModal] = useState(false);

  const [subjectData, setSubjectData] = useState();

  const handleChange = (fieldName, event) => {
    // console.log(event.target.value);
    setSubjectData({ ...subjectData, [fieldName]: event.target.value });
  };

  const handlelogin = () => {
    resetPassword(subjectData, (err, data) => {
      if (!err) {
        // console.log("DONE");
      }
    });

    setSubjectModal(false);
  };

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />

          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Don’t have an account? {''}
              <Link variant="subtitle2" component={RouterLink} to="/student/Register">
                Get started
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/static/illustrations/illustration_login.png" alt="login" />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Sign in to Exam System
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>

            {/* <AuthSocial /> */}

            <UserLoginForm />

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
              {' '}
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Button
                variant="contained"
                style={{ background: '#880ED4' }}
                component={RouterLink}
                to={'/user/register'}
              >
                Create New Account
              </Button>

              <Button variant="contained" style={{ background: '#A62E2E' }} onClick={() => setSubjectModal(true)}>
                Forgot Password
              </Button>
            </Stack>

            {/* {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </Typography>
            )} */}
          </ContentStyle>
        </Container>

        <div>
          <Dialog open={subjectModal} onClose={() => setSubjectModal(false)}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              {' '}
            </Stack>
            <DialogContent>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  name="name"
                  label="Your Phone Number"
                  style={{ width: '100%' }}
                  value={get(subjectData, 'phone', '')}
                  onChange={(e) => {
                    handleChange('phone', e);
                  }}
                  required
                />
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                onClick={() => handlelogin()}
                style={{ color: 'white', backgroundColor: 'blue' }}
              >
                GET NEW PASSWORD
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </RootStyle>
    </Page>
  );
}
