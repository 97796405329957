import { getReqAuth, postReq, postReqAuth } from '../../api';

// 1. Get Exam List ( User )
export const getAllExams = (limit, page, cb) =>
	postReqAuth("/webapi/student/exam/get/all", {limit, page}, "", cb);

// 2. Get Exam Detail ( User )
export const getExamDetail = (id, cb) => getReqAuth("/webapi/student/exam/get/detail/", {}, id, cb);

// 2. Get Exam Detail ( User )
export const getExamSolution = (id, cb) => getReqAuth("/webapi/student/exam/get/solution/", {}, id, cb);

// 3. Enroll exam ( User )
export const enrollExam = (id, cb) => getReqAuth("/webapi/student/exam/enroll/", {}, id, cb);

// 4. Get enrolled Exam List ( User )
export const getEnrolledExams = (limit, page, cb) =>
	postReqAuth("/webapi/student/exam/get/enrolled", {limit, page}, "", cb);

// 5. publish exam
export const finishExam = (id, data, cb) => postReqAuth("/webapi/student/exam/finish/", data, id, cb);

// 6. Leaderboard
export const getMeritList = (examId, data, cb) =>
	postReqAuth("/webapi/student/exam/leaderboard/", data, examId, cb);

// 7. Leaderboard batch
export const getMeritListBatch = (examId, batchId, cb) =>
	postReqAuth("/webapi/student/exam/leaderboard/batch", {examId, batchId}, "", cb);

// 8. Leaderboard branch
export const getMeritListBranch = (examId, branchId, cb) =>
	postReqAuth("/webapi/student/exam/leaderboard/branch", {examId, branchId}, "", cb);

// 9. Leaderboard branch
export const searchMeritList = (examId, data, cb) =>
	postReqAuth("/webapi/student/exam/leaderboard/search/", data, examId, cb);

// 10. getScore
export const getExamScore = (examId, cb) => {
	getReqAuth("/webapi/student/exam/get/score/", {}, examId, cb);
};

export const getExamDetailsFromCode = (examCode, cb) => {
	getReqAuth("/webapi/student/exam/qrExam/", {}, examCode, cb);
};


export const getPublicExamDetails = (data, cb) =>
	postReq("/webapi/student/exam/get/publicExam/detail", data , "", cb);

export const getPublicExamStartTime = (data, cb) =>
	postReq("/webapi/student/exam/publicExam/timing", data , "", cb);

export const getServerTimingForPublicExam = (data, cb) =>
	postReq("/webapi/student/exam/serverTime", data , "", cb);

export const finishPublicExam = (id, data, cb) => postReq("/webapi/student/exam/public/finish/", data, id, cb);

export const getPublicExamScore = (data, cb) => {
	postReq("/webapi/student/exam/public/get/score", data , "", cb);
};

export const uploadMobileOMR = (data, cb) => {
	postReq("/webapi/student/exam/process", data , "", cb);
};
