import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Card, CardActions, CardContent, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { emptyQuestion } from 'src/util/helpers';

Question.propTypes = {
  tempQuestion: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  saveMcq: PropTypes.func.isRequired,
};

export default function Question({ tempQuestion, answers, saveMcq }) {
  const [question, setQuestion] = useState(tempQuestion);
  const [answer, setAnswer] = useState(answers);

  const handleCheckBox = (jdx) => {
    let tempAnswer = new Set(answer);
    if (tempAnswer.has(jdx)) tempAnswer.delete(jdx);
    // else assign
    else tempAnswer.add(jdx);

    setAnswer(tempAnswer);
  };

  const handleEditorTxt = (content) => {
    let editedQuestion = JSON.parse(JSON.stringify(question));
    editedQuestion.question = content;
    setQuestion(editedQuestion);
  };

  const handleEditorTxtOptions = (content, idx) => {
    let editedQuestion = JSON.parse(JSON.stringify(question));
    editedQuestion.options[idx].title = content;
    setQuestion(editedQuestion);
  };

  return (
    <div>
      <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
        <CardContent>
          <div style={{ display: 'flex', width: '100%' }}>
            <div
              style={{ width: '10%', margin: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <div
                style={{
                  backgroundColor: '#800000',
                  textAlign: 'center',
                  borderRadius: '50%',
                  color: 'white',
                  width: '200px',
                }}
              >
                Question
              </div>
            </div>
            <div style={{ width: '90%', marginLeft: '20px' }}>
              <CKEditor
                editor={ClassicEditor}
                data={question.question}
                // onReady={ editor => {
                //     // You can store the "editor" and use when it is needed.
                //     console.log( 'Editor is ready to use!', editor );
                // } }
                onChange={(event, editor) => {
                  const data = editor.getData();
                  // console.log( { event, editor, data } );
                  handleEditorTxt(data);
                }}
                // onBlur={ ( event, editor ) => {
                //     console.log( 'Blur.', editor );
                // } }
                // onFocus={ ( event, editor ) => {
                //     console.log( 'Focus.', editor );
                // } }
              />
            </div>
          </div>
          {question.options.map((obj, jdx) => (
            <React.Fragment key={jdx}>
              <div style={{ width: '100%', height: 1, margin: 5, background: 'grey' }}></div>
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: '10%', display: 'flex', justifyContent: 'center', margin: '4px' }}>
                  <div style={{}}>
                    <div
                      style={{
                        width: 35,
                        backgroundColor: '#800000',
                        textAlign: 'center',
                        borderRadius: '50%',
                        color: 'white',
                      }}
                    >
                      {jdx + 1}
                    </div>
                    <Checkbox
                      checked={answer.has(jdx)}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      onClick={() => handleCheckBox(jdx)}
                    />
                  </div>
                </div>
                <div style={{ width: '90%', marginLeft: '20px' }}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={question.options[jdx].title}
                    // onReady={ editor => {
                    //     // You can store the "editor" and use when it is needed.
                    //     console.log( 'Editor is ready to use!', editor );
                    // } }
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log( { event, editor, data } );
                      // console.log('option');
                      // console.log(jdx);
                      handleEditorTxtOptions(data, jdx);
                    }}
                    // onBlur={ ( event, editor ) => {
                    //     console.log( 'Blur.', editor );
                    // } }
                    // onFocus={ ( event, editor ) => {
                    //     const data = editor.getData();
                    //     console.log('option');
                    //     console.log(jdx);
                    //     handleEditorTxtOptions(data, jdx);
                    // } }
                  />
                </div>
              </div>

              {/* <div className={classes.inputContainer}>
                                    <input
                                        type="file"
                                        onChange={(event) => this.onFileChange(jdx, event)}
                                        className="file-input"
                                    />
                                </div> */}

              {question.options[jdx].titleImage ? (
                <div className={classes.inputContainer}>
                  <div className={classes.crossContainer}>
                    <CancelIcon style={{ color: 'white' }} onClick={() => this.setFileToNull(jdx)} />
                  </div>
                  <img src={URL.createObjectURL(question.options[jdx].titleImage)} alt="" className={classes.dp} />
                </div>
              ) : null}
            </React.Fragment>
          ))}
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <Button
              style={{ backgroundColor: '#800000', color: 'white', padding: '10px 40px' }}
              onClick={() => {
                saveMcq(question, answer);
                setQuestion(JSON.parse(JSON.stringify(emptyQuestion)));
                setAnswer(new Set());
              }}
            >
              Save
            </Button>
          </div>
        </CardActions>
      </Card>
    </div>
  );
}
