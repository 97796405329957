import Iconify from '../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export function formatDateMonth(monthIndex) {
  var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  return monthNames[monthIndex];
}

export function formatDate(date) {
  var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  var hours = date.getHours();
  var minutes = date.getMinutes();
  // var seconds = date.getSeconds();
  // Aug 2 2019 at 12:12
  return `${day} ${monthNames[monthIndex]}, ${year} ${hours}:${minutes}`;
}

export const sessions = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034];

export const nextStep = ['Call Again', 'Check Payment', 'Admit to Course', 'Decline Registration', 'Others'];

export const applicationStatus = ['New', 'Admitted', 'Declined', 'Banned'];

export const courseTypes = [
  'Mobile Scan OMR Course',
  'Offline Class Course',
  'Online Video Content Course',
  'Exam Batch',
];

export const paymentMethods = ['Cash', 'Bank', 'BKash', 'Nagad', 'Rocket', 'Others'];
export const paymentTypes = ['One-Time', 'Monthly'];
export const paymentMonth = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const activeStatus = ['true', 'false'];

export const paymentStatusList = ['paid', 'unpaid'];

export const emptyQuestion = {
  question: '',
  titleImage: null,
  options: [
    {
      title: '',
      titleImage: null,
    },
    {
      title: '',
      titleImage: null,
    },
    {
      title: '',
      titleImage: null,
    },
    {
      title: '',
      titleImage: null,
    },
  ],
};

export const getNavElementsForAdmin = (roleSet) => {
  const navElements = [];

  if (roleSet.length === 0) {
    return navElements;
  }

  if (roleSet.includes('CHECK_OTHER_INSTITUTE')) {
    navElements.push({
      title: 'Switch Institute',
      path: '/dashboard/switchInstitute',
      icon: getIcon('icon-park-outline:change'),
    });
  }

  navElements.push(
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
      title: 'Students',
      path: '/dashboard/user',
      icon: getIcon('solar:user-check-bold'),
    },
    {
      title: 'Online Exam',
      path: '/dashboard/onlineExam',
      icon: getIcon('healthicons:i-exam-multiple-choice'),
    },
    {
      title: 'Course Details',
      path: '/dashboard/courseList',
      icon: getIcon('healthicons:i-exam-multiple-choice'),
    },
    {
      title: 'New/Block Students',
      path: '/dashboard/unverified/students',
      icon: getIcon('eva:people-fill'),
    },
    {
      title: 'Institute',
      path: '/dashboard/institute',
      icon: getIcon('mdi:home'),
    },
    {
      title: 'SMS System',
      path: '/dashboard/smsSystem',
      icon: getIcon('fa-solid:sms'),
    },
    {
      title: 'Dynamic SMS',
      path: '/dashboard/dynamicSmsSystem',
      icon: getIcon('subway:sms-1'),
    },
    {
      title: 'Course Registrations',
      path: '/dashboard/course_enrollments',
      icon: getIcon('hugeicons:course'),
    },
    {
      title: 'Student Admission',
      path: '/dashboard/register_student',
      icon: getIcon('eva:person-add-fill'),
    }
  );

  if (roleSet.includes('EDIT_PAYMENT') || roleSet.includes('VIEW_PAYMENT')) {
    navElements.push(
      {
        title: 'Collected Payments',
        path: '/dashboard/payment',
        icon: getIcon('mdi:currency-bdt'),
      },

      {
        title: 'Due Payments',
        path: '/dashboard/due-payment',
        icon: getIcon('mdi:currency-bdt'),
      }
      // {
      //   title: 'Batchwise Payment',
      //   path: '/dashboard/batchWisePaymentSummary',
      //   icon: getIcon('fluent:wallet-credit-card-28-filled'),
      // },
    );
  }

  // if (roleSet.includes('VIEW_PAYMENT')) {
  //   navElements.push({
  //     title: 'Due Payments',
  //     path: '/dashboard/due-payment',
  //     icon: getIcon('mdi:currency-bdt'),
  //   });
  // }

  return navElements;
};
