import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// APIS
// material
import { Button, Card, CardContent, Container, Stack, TextField, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import { MuiFileInput } from 'mui-file-input';
import { getExamDetailsFromCode, uploadMobileOMR } from '../../axiosLib/services/exam/user';
import { get } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function MobileOmrUpload() {
  const navigate = useNavigate();

  const [file, setFile] = useState(null);

  const [alertModal, setAlertModal] = useState(false);

  const [examCode, setExamCode] = useState('');

  const [examDetails, setExamDetails] = useState({});

  const handleChangeExcel = (event) => {
    setFile(event);
  };

  const getExamCode = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    return urlParams.get('examCode');
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('examCode', getExamCode());

    uploadMobileOMR(formData, (error, cItm) => {
      if (!error) {
        const code = getExamCode();

        alert('Successfully Uploaded File');

        sessionStorage.setItem('cItm:' + code, JSON.stringify(cItm));

        navigate('/user/checkFilledOmrAnswers?examCode=' + code);
      } else {
        alert('Please upload a valid image file');
      }
    });
  };

  useEffect(() => {
    const examCode = getExamCode();

    getExamDetailsFromCode(examCode, (err, data) => {
      if (!err) {
        setExamDetails(data);
      }
    });
  }, []);

  return (
    <Page title="OMR Sheet Upload">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            ওএমআর শিটের ছবি আপলোড পেইজ
          </Typography>
        </Stack>

        {get(examDetails, 'name', '') !== '' ? (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
                <CardContent
                  style={{ backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography variant="h2">Exam Details</Typography>
                    <Typography variant="h3">{get(examDetails, 'name', '')}</Typography>
                    <Typography variant="h5">{`Exam Code: ${get(examDetails, 'code', '')}`}</Typography>
                    <Typography variant="h5">{`Total Questions: ${get(
                      examDetails,
                      'numberOfQuestions',
                      ''
                    )}`}</Typography>
                    <Typography variant="h5">{`Negative Marks/Question: ${get(
                      examDetails,
                      'negativeMarks',
                      ''
                    )}`}</Typography>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
                <CardContent
                  style={{ backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography variant="h2">গুরুত্বপূর্ণ নির্দেশনা</Typography>
                    <Typography variant="h5">
                      ১ : ওএমআর এর ছবি Cam Scanner সফটওয়্যার ব্যাবহার করে সুন্দর ভাবে এডিট করে নিতে হবে। এই সংক্রান্ত
                      দিক-নির্দেশনা নিচের বাটনে ক্লিক করে জেনে নিতে পারবে।{' '}
                    </Typography>
                    <Typography variant="h5">
                      ২ : ওএমআর এর ছবি স্পষ্ট হওয়া খুবই জরুরি। তবে তোমাদের সুবিধার জন্য, ছবি আপলোড করার পরে কোন কোন-কোন
                      আপশন ফিলাপ করেছো, সেটা তোমরা দেখতে পাবে। কোনো সমস্যা থাকলে, উত্তর চেঞ্জ করে তারপর সাবমিট করতে
                      পারবে।
                    </Typography>
                    <Typography variant="h5">
                      ৩ : শুধুমাত্র ছবি আপলোড করা যাবে এবং ছবির সাইজ অবশ্যই ৬ মেগাবাইটের কম হতে হবে। Cam Scanner
                      ব্যাবহার করলে ছবির সাইজ সব সময় ছোট পাওয়া যাবে।
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        alert('Process');
                      }}
                      style={{ color: 'white', backgroundColor: 'blue', width: '100%', marginRight: 15 }}
                    >
                      ওএমআর আপলোডের নির্দেশনা দেখতে এখানে ক্লিক করুন
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <MuiFileInput
                value={file}
                onChange={(e) => {
                  handleChangeExcel(e);
                }}
                name="file"
                placeholder="ওএমআর এর ছবি আপলোড করুন"
                style={{ width: '100%' }}
              ></MuiFileInput>

              <Button
                variant="contained"
                onClick={() => {
                  UploadFile();
                }}
                style={{ color: 'white', width: '100%' }}
              >
                Upload OMR Sheet
              </Button>
            </Stack>
          </div>
        ) : (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Card sx={{ width: '90%', marginBottom: '30px', border: '2px solid #800000' }}>
                <CardContent
                  style={{ backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography variant="h3">
                      ওএমআর শিট সাবমিট করার জন্য প্রথমে আমাদের নিচের দুইটি কাজের যে কোনো একটি করতে হবেঃ
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Card sx={{ width: '90%', marginBottom: '30px', border: '2px solid #800000' }}>
                <CardContent style={{ backgroundColor: 'beige', color: '#800000', fontSize: '20px' }}>
                  <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Typography variant="h6">
                      ১ঃ প্রশ্নপত্রে দেওয়া <strong>QR CODE</strong> মোবাইল দিয়ে স্ক্যান করতে হবে।
                    </Typography>
                    <Typography variant="h6">- - - - - অথবা - - - -</Typography>
                    <Typography variant="h6">
                      ২ঃ প্রশ্নপত্রে দেওয়া এক্সাম কোডটি নিচের বক্সে লিখে এক্সাম সার্চ করতে হবে।
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="examCode"
                label="Exam Code From Question"
                value={examCode}
                style={{ width: '100%' }}
                onChange={(e) => {
                  setExamCode(e.target.value.trim());
                }}
              />

              <Button
                variant="contained"
                onClick={() => {
                  getExamDetailsFromCode(examCode, (err, data) => {
                    if (!err) {
                      const currentUrl = new URL(window.location.href);
                      const params = currentUrl.searchParams;

                      // Add or update a parameter (e.g., `key=value`)
                      params.set('examCode', examCode);

                      // Update the URL without reloading
                      window.location.href = `${currentUrl.origin}${currentUrl.pathname}?${params.toString()}`;
                    } else {
                      setAlertModal(true);
                    }
                  });
                }}
                style={{ color: 'white', width: '100%' }}
              >
                Search Exam
              </Button>
            </Stack>
          </div>
        )}

        {/* ALERT MODAL*/}
        <div>
          <Dialog open={alertModal} onClose={() => setAlertModal(false)}>
            <DialogContent>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <p>=> এক্সাম কোডটির সাথে আমাদের কোনো এক্সামের মিল পাওয়া যায়নি</p>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <p>=> অথবা এই কোর্সের মেয়াদ শেষ হয়ে গেছে।</p>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }}>
                <p>=> অথবা এই কোর্সে তুমি বর্তমানে ভর্তি নেই।</p>
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setAlertModal(false)}
                style={{ color: 'white', backgroundColor: 'blue' }}
              >
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </Page>
  );
}
