import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

// APIS
// material
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import moment from 'moment';
import { Downloading } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AppWidget from 'src/sections/@dashboard/app/AppWidget';
import { getAllPaymentsByBatch, getAllPaymentsByBatchForExcel } from 'src/axiosLib/services/payment/admin';
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD_BATCH = [
  { id: 'amount', label: 'Payment Amount', alignRight: false },
  // { id: 'Payment Method', label: 'Payment Method', alignRight: false },
  { id: 'username', label: 'User name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'roll', label: 'Roll', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function BatchWisePaymentDetails() {
  const navigate = useNavigate();

  const [paymentRowsPerPage, setPaymentRowsPerPage] = useState(100);

  const [paymentReqBody, setPaymentReqBody] = useState({
    fromDate: moment().format('yyyy-MM-DD'),
    toDate: moment().format('yyyy-MM-DD'),
  });

  const [batchInfo, setBatchInfo] = useState({});

  const [paymentList, setPaymentList] = useState([]);

  const [paymentCount, setPaymentCount] = useState(0);

  const [paymentSummary, setPaymentSummary] = useState([]);

  const [paymentPage, setPaymentPage] = useState(0);

  const handleChangePagePayment = (event, newPage) => {
    setPaymentPage(newPage);
  };

  const handleChangeRowsPerPagePayment = (event) => {
    setPaymentRowsPerPage(event.target.value);
    setPaymentPage(0);
  };

  const getAllPaymentsOfStudents = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const tempObj = {
      batchId: urlParams.get('batchId'),
      fromDate: moment(paymentReqBody.fromDate).format('yyyy-MM-DD'),
      toDate: moment(paymentReqBody.toDate).format('yyyy-MM-DD'),
      limit: paymentRowsPerPage,
      page: paymentPage,
    };

    getAllPaymentsByBatch(tempObj, (error, data) => {
      // console.log(data)
      if (!error) {
        // console.log(data);
        setPaymentList(get(data, 'userPayments', []));
        setPaymentSummary(get(data, 'paymentCollected', []));

        setPaymentCount(get(data, 'paymentCollected[0].totalTransactions', 0));
        setBatchInfo(get(data, 'batchInfo', {}));
      } else {
        navigate('/login', { replace: true });
      }
    });
  };

  const downloadExcelOfAllPayments = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const tempObj = {
      batchId: urlParams.get('batchId'),
      fromDate: moment(paymentReqBody.fromDate).format('yyyy-MM-DD'),
      toDate: moment(paymentReqBody.toDate).format('yyyy-MM-DD'),
      limit: paymentRowsPerPage,
      page: paymentPage,
    };

    getAllPaymentsByBatchForExcel(tempObj, (error, data) => {
      if (!error) {
        var XLSX = require('xlsx');
        const worksheet = XLSX.utils.json_to_sheet(data.userPayments);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Payments_Of_ ' + data.batchInfo.batchName + '_Batch' + '.xlsx');
      } else {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    getAllPaymentsOfStudents();
  }, [paymentPage, paymentRowsPerPage]);

  return (
    <Page title="Revenue Track">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidget
              title="Payment Collected in BDT"
              total={get(paymentSummary[0], 'totalPaid', 0)}
              icon={'subway:sms-3'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidget
              title="Tentative Revenue in BDT"
              total={get(paymentSummary[0], 'totalCourseFee', 0)}
              color="info"
              icon={'fa6-solid:users-rays'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidget
              title="Total Paid Students"
              total={get(paymentSummary[0], 'totalUsers', 0)}
              color="warning"
              icon={'mdi:register'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidget
              title="Total Transactions"
              total={get(paymentSummary[0], 'totalTransactions', 0)}
              color="error"
              icon={'mdi:server-security'}
            />
          </Grid>
        </Grid>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
          <Typography variant="h4" gutterBottom>
            {get(batchInfo, 'batchName', '')} -- Batch Revenue Tracking Tool
          </Typography>
          {/* <Button variant="contained" component={RouterLink} to="/dashboard/register_student" 
          startIcon={<Iconify icon="eva:plus-fill"/>}>
            Add New Student
          </Button> */}
        </Stack>

        {/* Batch */}
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            {' '}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-around" spacing={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Transaction From Date"
                name="fromDate"
                value={moment(get(paymentReqBody, 'fromDate')).format('yyyy-MM-DD')}
                onChange={(newValue) => {
                  setPaymentReqBody({ ...paymentReqBody, fromDate: new Date(newValue) });
                  // setexamInfo({...examInfo, startTime: new Date(newValue)});
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} />}
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Transaction to Date"
                name="toDate"
                value={moment(get(paymentReqBody, 'toDate')).format('yyyy-MM-DD')}
                onChange={(newValue) => {
                  setPaymentReqBody({ ...paymentReqBody, toDate: new Date(newValue) });
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} />}
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </LocalizationProvider>

            <Button
              variant="contained"
              onClick={(e) => {
                getAllPaymentsOfStudents();
              }}
              style={{ color: 'white', backgroundColor: 'blue', width: '20%', marginRight: 15 }}
            >
              {/* <Downloading style={{color: "white", marginRight: 5}}/> */}
              FILTER
            </Button>

            <Button
              variant="contained"
              onClick={(e) => downloadExcelOfAllPayments()}
              style={{ color: 'white', backgroundColor: 'blue', width: '30%', marginRight: 15 }}
            >
              <Downloading style={{ color: 'white', marginRight: 5 }} />
              Download Excel
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD_BATCH}
                  rowCount={paymentList.length}
                  // onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {paymentList.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'paid', 0)}</TableCell>
                        <TableCell align="left"> {get(row, 'username', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'phone', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'roll', '')}</TableCell>
                        <TableCell align="left"> {moment(get(row, 'createdAt')).format('yyyy-MM-DD')}</TableCell>
                        <TableCell align="left">
                          {get(row, 'courseFee', 0) - get(row, 'paid', 0) > 0 ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              DUE {get(row, 'courseFee', 0) - get(row, 'paid', 0)} BDT
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              style={{
                                background: 'green',
                                color: '#fff',
                              }}
                            >
                              PAID
                            </Button>
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to={'/dashboard/register_student/?std=' + get(row, '_id')}
                            >
                              VIEW
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100, 150]}
            component="div"
            count={paymentCount}
            rowsPerPage={paymentRowsPerPage}
            page={paymentPage}
            onPageChange={handleChangePagePayment}
            onRowsPerPageChange={handleChangeRowsPerPagePayment}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>
      </Container>
    </Page>
  );
}
