import { getReqAuth, postReqAuth } from '../../api';

// 1. Create New Branch
export const createBranch = (data, cb) => postReqAuth('/webapi/institute/branch/create', data, '', cb);

// 2. Get All Branches
export const getAllBranches = (limit, page, cb) =>
  postReqAuth('/webapi/institute/branch/get/all', { limit, page }, '', cb);

// 3. Get Branch Detail
export const getBranchDetail = (id, cb) => getReqAuth('/webapi/institute/branch/get/detail/', {}, id, cb);
