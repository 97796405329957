import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider } from '../../../components/hook-form';

import moment from 'moment';
import { get } from 'lodash';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { activeStatus, paymentMethods, paymentMonth, paymentTypes, sessions } from 'src/util/helpers';
import { getAllBranches } from 'src/axiosLib/services/branch/admin';
import { getAllBatches } from 'src/axiosLib/services/batch/admin';
import {
  changeUserPass,
  createStudent,
  getUserDetail,
  getUserPerformanceExcel,
  getUserPerformanceOfExams,
  updateStudent,
} from 'src/axiosLib/services/user/admin';
import Scrollbar from 'src/components/Scrollbar';
import { UserListHead } from 'src/sections/@dashboard/user';
import { Downloading, Edit } from '@mui/icons-material';
import { getUserPayments, removePaymentHistory, setPaymentInDb } from 'src/axiosLib/services/payment/admin';
import { adminLevel, getAllInstitutions } from '../../../axiosLib/services/institution/admin';
import config from '../../../util/config';
import { getAllCoursesOfOneStudent } from '../../../axiosLib/services/course/admin';

// ----------------------------------------------------------------------

export default function RegisterStudent() {
  const navigate = useNavigate();

  const [studentInfo, setStudentInfo] = useState({ validTill: moment().format('DD/MM/YYYY'), session: 2024 });
  const [branches, setBranches] = useState([]);
  const [batches, setBatches] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [alertContent, setAlertContent] = useState('');
  const [studentId, setStudentId] = useState(null);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentHistoryModal, setPaymentHistoryModal] = useState(false);
  const [adminData, setAdminData] = useState({});

  const [examList, setExamList] = useState([]);

  const [adminStatus, setAdminStatus] = useState('admin');

  const [totalCollected, setTotalCollected] = useState(0);

  const [paymentPage, setPaymentPage] = useState(0);

  const [paymentRowsPerPage, setPaymentRowsPerPage] = useState(10);

  const [paymentList, setPaymentList] = useState([]);

  const [paymentCount, setPaymentCount] = useState(0);

  const [paymentPopUpData, setPaymentPopUpData] = useState({});

  const [paymentHistory, setPaymentHistory] = useState([{}]);

  const [editable, setEditable] = useState(false);

  const [enrolledCourses, setEnrolledCourses] = useState([]);

  const TABLE_HEAD = [
    { id: 'name', label: 'Exam Name', alignRight: false },
    { id: 'session', label: 'Exam Session', alignRight: false },
    { id: 'maxMarks', label: 'Highest Marks', alignRight: false },
    // { id: 'session', label: 'Session', alignRight: false },
    { id: 'mymarks', label: 'My Marks', alignRight: false },
    // { id: 'present', label: 'Status', alignRight: false },
    { id: 'totalMarks', label: 'Total Marks', alignRight: false },
    { id: 'correct', label: 'Correct', alignRight: false },
    { id: 'incorrect', label: 'Incorrect', alignRight: false },
    // { id: 'registered_at', label: 'Registered at', alignRight: false },
    { id: 'skipped', label: 'Skipped', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
  ];

  const TABLE_HEAD_PAYMENT = [
    { id: 'amount', label: 'Payment Amount', alignRight: false },
    { id: 'courseName', label: 'Course Name', alignRight: false },
    { id: 'pT', label: 'Payment Type', alignRight: false },
    { id: 'ym', label: 'Year-Month', alignRight: false },
    { id: 'Payment Method', label: 'Payment Method', alignRight: false },
    { id: 'details', label: 'Details', alignRight: false },
    { id: 'verifiedBy', label: 'Received By', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'Collection Status', label: 'Collection Status', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
  ];

  const TABLE_HEAD_ENROLLED_COURSES = [
    { id: 'amount', label: 'Course Name', alignRight: false },
    { id: 'Payment Method', label: 'Price', alignRight: false },
    { id: 'details', label: 'Discounted/Actual Price', alignRight: false },
    { id: 'ref', label: 'Reference', alignRight: false },
    { id: 'payTYpe', label: 'Payment Type', alignRight: false },
    { id: 'verifiedBy', label: 'Enrollment Status', alignRight: false },
    { id: 'date', label: 'Enrollment Date', alignRight: false },
  ];

  const TABLE_HISTORY = [
    { id: 'serial-s', label: 'Serial', alignRight: false },
    { id: 'amount', label: 'Payment Amount', alignRight: false },
    { id: 'status', label: 'Active', alignRight: false },
    { id: 'Payment Method', label: 'Payment Method', alignRight: false },
    { id: 'Received By', label: 'Received By', alignRight: false },
    { id: 'Edited By', label: 'Edited By', alignRight: false },
    { id: 'date', label: 'Update Date', alignRight: false },
    { id: 'Notes', label: 'Note', alignRight: false },
    { id: 'paymentDetails', label: 'Payment Details', alignRight: false },
    { id: 'type-s', label: 'Payment Type', alignRight: false },
    { id: 'forMonth', label: 'Month', alignRight: false },
    { id: 'years-s', label: 'Year', alignRight: false },
    { id: 'course-name', label: 'Course Name', alignRight: false },
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    window.location.reload(false);
  };

  const handleChangePagePayment = (event, newPage) => {
    setPaymentPage(newPage);
  };

  const handleChangeRowsPerPagePayment = (event) => {
    setPaymentRowsPerPage(event.target.value);
    setPaymentPage(0);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const stdId = urlParams.get('std');

    adminLevel((error, admData) => {
      if (!error) {
        setAdminData(get(admData, 'admin'));
        setEditable(!stdId);

        if (stdId) {
          getUserDetail(stdId, (err, data) => {
            if (!err) {
              setStudentInfo(data);
              setStudentId(stdId);

              if (data.paymentId.length > 0) {
                setAdminStatus('super-admin');
              }

              setEditable(
                stdId === '' ||
                  get(data, 'institutionId._id', 'X').toString() === get(admData, 'admin.institutionId._id', 'Y')
              );
            }
          });
        }
      } else {
        console.log(error);
      }
    });

    getAllBranches(100, 0, (err, data) => {
      if (!err) {
        const branches = data.branches;
        const branchDetails = branches.map((branch) => {
          return { id: branch._id, branchName: branch.branchName };
        });
        setBranches(branchDetails);
        // console.log(branchDetails);
      }
    });

    getAllCoursesOfOneStudent(stdId, (error, data) => {
      if (!error) {
        console.log(data.courses);
        setEnrolledCourses(get(data, 'courses', []));
      }
    });

    getAllInstitutions((err, data) => {
      if (!err) {
        const instituteDetails = data.map((institute) => {
          return { _id: institute._id, institutionName: institute.institutionName };
        });

        setInstitutes(instituteDetails);
      }
    });

    getAllBatches(1000, 0, (err, data) => {
      if (!err) {
        const batches = data.batches;
        const batchDetails = batches.map((batch) => {
          return { id: batch._id, batchName: batch.batchName };
        });
        setBatches(batchDetails);
        // console.log(batchDetails);
      }
    });

    getUserPayments(stdId, (error, data) => {
      if (!error) {
        // console.log(data.payments);
        let payments = get(data, 'payments', []);
        let total = payments.length;

        setPaymentList(payments);
        setPaymentCount(total);

        let money = 0;

        for (let i = 0; i < payments.length; i++) {
          if (payments[i].isActive) {
            money = money + payments[i].amount;
          }
        }

        setTotalCollected(money);
      } else {
        console.log(error);
      }
    });

    if (urlParams.get('std')) {
      getUserPerformanceOfExams(30, 0, urlParams.get('std'), (err, data) => {
        if (!err) {
          setExamList(get(data, 'exams', []));
        }
      });
    }
  }, []);

  const resetPass = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const stdId = urlParams.get('std');

    changeUserPass(stdId, (err, data) => {
      if (!err) {
        alert('Password Changed and SMS Sent');
      } else {
        alert('Could not change Password');
      }
    });
  };

  const handleChange = (fieldName, event) => {
    if (fieldName === 'validTill') {
      setStudentInfo({ ...studentInfo, [fieldName]: moment(event.target.value).format('DD/MM/YYYY') });
    } else if (fieldName === 'institutionId') {
      let data = institutes.filter((item) => item.institutionName === event.target.value)[0];

      setStudentInfo({ ...studentInfo, [fieldName]: data });

      // console.log(studentInfo)
    } else {
      setStudentInfo({ ...studentInfo, [fieldName]: event.target.value });
    }
  };

  const handleChange2 = (fieldName, event) => {
    const {
      target: { value },
    } = event;
    const branchNames = typeof value === 'string' ? value.split(',') : value;
    setStudentInfo({
      ...studentInfo,
      [fieldName]: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const deleteChip = (fieldName, chipToDelete) => {
    const values = get(studentInfo, fieldName, []);
    setStudentInfo({
      ...studentInfo,
      [fieldName]: values.filter((value) => {
        return value !== chipToDelete;
      }),
    });
  };

  const handlePaymentDataChange = (fieldName, event) => {
    // console.log(event.target.value);
    setPaymentPopUpData({ ...paymentPopUpData, [fieldName]: event.target.value });
  };

  const getName = (fieldName, idTOSearch) => {
    // console.log(fieldName , idTOSearch );
    if (typeof idTOSearch === 'object') {
      idTOSearch = get(idTOSearch, '_id');
    }
    if (fieldName === 'branch') {
      const selectedBranch = branches.filter((branch) => {
        return branch.id === idTOSearch;
      });

      return selectedBranch[0] ? selectedBranch[0].branchName : null;
    } else if (fieldName === 'batch') {
      const selectedBatch = batches.filter((batch) => {
        return batch.id === idTOSearch;
      });

      return selectedBatch[0] ? selectedBatch[0].batchName : null;
    } else if (fieldName === 'course') {
      const selectedCourse = enrolledCourses.filter((course) => {
        return course._id === idTOSearch;
      });

      return selectedCourse[0] ? selectedCourse[0].title : null;
    }
  };

  const getViewStudentUrlOnError = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let stdId = alertContent.split('->').length === 2 ? alertContent.split('->')[1].trim() : urlParams.get('std');

    let link = config.WEB_LINK + 'dashboard/register_student/?std=' + stdId;

    window.open(link, '_blank');
  };

  const getBackgroundStylingOfPaymentHistoryCell = (row, element, rowIndex) => {
    const data = get(paymentHistory, 'data', []);
    const isDifferent = rowIndex > 0 && get(row, element, '') !== get(data[rowIndex - 1], element, '');

    return {
      backgroundColor: isDifferent ? 'red' : 'transparent',
      color: isDifferent ? 'white' : 'inherit',
      padding: '2px 6px',
      borderRadius: '4px',
    };
  };

  const handlePaymentEdit = (row) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const stdId = urlParams.get('std');
    const now = new Date();

    const temp = {
      userId: stdId,
      _id: get(row, '_id', null),
      amount: get(row, 'amount', 0),
      paymentDetails: get(row, 'paymentDetails', ''),
      note: get(row, 'note', ''),
      paymentMethod: get(row, 'paymentMethod', paymentMethods[0]),
      type: get(row, 'type', paymentTypes[0]),
      year: get(row, 'year', now.getFullYear()),
      collectionForMonth: get(row, 'collectionForMonth', now.toLocaleString('en-US', { month: 'long' })),
      createdAt: get(row, 'createdAt', new Date()),
      isActive: get(row, 'isActive', 'true'),
      courseId: get(row, 'courseId._id', ''),
    };

    setPaymentPopUpData(temp);

    setPaymentModal(true);
  };

  const handlePaymentHistory = (row, paymentId) => {
    const currentData = {
      'prevAmount' :  get(row, 'amount', ''),
      'prevAdminId' : get(row, 'verifiedBy', {}),
      'adminId' : get(row, 'verifiedBy', {}),
      'prevPaymentMethod' : get(row, 'paymentMethod', ''),
      'type' : get(row, 'type', ''),
      'collectionForMonth' : get(row, 'collectionForMonth', ''),
      'year' : get(row, 'year', ''),
      'courseId' : get(row, 'courseId', {}),
      'previsActive' : get(row, 'isActive', {}),
      'prevNote' : get(row, 'note', ''),
      'editingDate' : get(row, 'editingDate', ''),
      '_id' : get(row, '_id', ''),
    }

    const allData = [...get(row, 'archive', []), currentData]

    setPaymentHistory({
      data: allData,
      id: paymentId,
      rowDetails: row,
    });

    setPaymentHistoryModal(true);
  };

  const setPaymentInDatabase = () => {
    // console.log(paymentPopUpData);
    setPaymentModal(false);

    setPaymentInDb(paymentPopUpData, (err, data) => {
      if (!err) {
        // console.log(data);

        window.location.reload(false);
      } else {
        console.log(err);
      }
    });
  };

  const removePaymentFromHistory = () => {
    // console.log(paymentHistory);
    removePaymentHistory(paymentHistory, (err, data) => {
      if (!err) {
        // console.log(data);

        window.location.reload(false);
      } else {
        console.log(err);
      }
    });
  };

  const handleStudentCreation = (studentInfo) => {
    createStudent(studentInfo, (err, data) => {
      setAlertContent('');
      if (!err) {
        onSubmit();
        return;
      }
      let errorMessage = '';
      for (const errId in err) {
        errorMessage = errorMessage + ', ' + err[errId];
      }
      errorMessage = errorMessage.slice(2);
      setAlertContent(errorMessage);
    });
  };

  const downloadExcel = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const stdId = urlParams.get('std');

    getUserPerformanceExcel(1000, 0, stdId, (error, data) => {
      if (!error) {
        var XLSX = require('xlsx');
        const worksheet = XLSX.utils.json_to_sheet(get(data, 'exams', []));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Student Performance.xlsx');
      } else {
        // console.log(error);
      }
    });
  };

  const handleStudentUpdate = (stdId, studentInfo) => {
    updateStudent(stdId, studentInfo, (err, data) => {
      setAlertContent('');
      if (!err) {
        onSubmit();
        return;
      }
      let errorMessage = '';
      for (const errId in err) {
        errorMessage = errorMessage + ', ' + err[errId];
      }
      errorMessage = errorMessage.slice(2);
      setAlertContent(errorMessage);
    });
  };
  const renderBasicCard = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Card
          sx={{
            width: '38%',
            marginBottom: '30px',
            marginRight: '30px',
            backgroundImage: 'linear-gradient(45deg, #CCFFFF, #E5CCFF)',
          }}
        >
          <CardContent>
            <div style={{ display: 'flex' }}>
              <Avatar
                sx={{ width: 100, height: 100, mr: 1.5 }}
                alt={get(studentInfo, 'username', '')}
                src={get(studentInfo, 'avatar', '')}
              />
              <div>
                <Typography variant="h3" component="div">
                  {get(studentInfo, 'username', '')}
                </Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  {get(studentInfo, 'phone')}
                </Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  Registed: {moment(get(studentInfo, 'createdAt')).format('DD/MM/YYYY')}
                </Typography>
                <Typography sx={{ mb: 1 }} color="text.secondary">
                  Valid Till: {moment(get(studentInfo, 'validTill')).format('DD/MM/YYYY')}
                </Typography>
              </div>
            </div>
          </CardContent>
          <CardActions>{editable ? <Button size="large">Remove Student</Button> : <div></div>}</CardActions>
        </Card>

        <Card sx={{ width: '60%', marginBottom: '30px', backgroundImage: 'linear-gradient(45deg, #CCFFFF, #E5CCFF)' }}>
          <CardContent>
            <Typography variant="h3" component="div">
              Status:
              {get(studentInfo, 'isVerified') == false ? (
                <Button
                  variant="h3"
                  style={{
                    background: 'red',
                    fontSize: '25px',
                    color: '#fff',
                  }}
                >
                  {get(studentInfo, 'status', '')}
                </Button>
              ) : (
                <Button
                  variant="h3"
                  style={{
                    background: 'green',
                    fontSize: '25px',
                    color: '#fff',
                  }}
                >
                  {get(studentInfo, 'status', '')}
                </Button>
              )}
            </Typography>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '5%' }}>
                <Typography sx={{ mb: 1 }} color="text.primary">
                  Branch
                </Typography>
                <div>
                  {get(studentInfo, 'branchId', []).map((branch) => {
                    return (
                      <Typography key={getName('branch', branch)} sx={{ mb: 1 }} color="text.secondary">
                        {getName('branch', branch)}
                      </Typography>
                    );
                  })}
                </div>
              </div>
              <div>
                <Typography sx={{ mb: 1 }} color="text.primary">
                  Batch
                </Typography>
                <div>
                  {get(studentInfo, 'batchId', []).map((batch) => {
                    return (
                      <Typography key={getName('batch', batch)} sx={{ mb: 1 }} color="text.secondary">
                        {getName('batch', batch)}
                      </Typography>
                    );
                  })}
                </div>
              </div>
            </div>

            <div>
              {/* <Typography sx={{ mb: 1 }} color="text.primary">
                College
              </Typography> */}
              <Typography color="text.primary">
                <b>{get(studentInfo, 'address', [])}</b>
              </Typography>
            </div>
          </CardContent>
          <CardActions>
            {editable ? (
              <Button
                size="large"
                onClick={(e) => {
                  resetPass();
                }}
              >
                Reset Password
              </Button>
            ) : (
              <div></div>
            )}
          </CardActions>
        </Card>
      </div>
    );
  };

  const renderExcel = () => {
    return (
      <div>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              Enrolled Courses
            </Typography>

            {/*{editable && (*/}
            {/*  <Button*/}
            {/*    variant="contained"*/}
            {/*    onClick={(e) => {*/}
            {/*      console.log(e);*/}
            {/*    }}*/}
            {/*    style={{ color: '#FFFFFF', backgroundColor: '#880ED4', marginRight: 15 }}*/}
            {/*  >*/}
            {/*    DUE = {get(studentInfo, 'courseFee', 0) - totalCollected} BDT*/}
            {/*  </Button>*/}
            {/*)}*/}
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD_ENROLLED_COURSES} rowCount={paymentList.length} />
                <TableBody>
                  {enrolledCourses.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'courseId.title', '')}</TableCell>
                        <TableCell align="left">
                          <strike>{get(row, 'courseId.discountedPrice', '')}</strike>
                        </TableCell>
                        <TableCell align="left">
                          <strong>{get(row, 'courseFee', '')}</strong>
                        </TableCell>
                        <TableCell align="left">
                          <strong>{get(row, 'reference', '')}</strong>
                        </TableCell>
                        <TableCell align="left"> {get(row, 'courseId.paymentType', '')}</TableCell>
                        <TableCell align="left">
                          {get(row, 'status') === 'Admitted' ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'green',
                                color: '#fff',
                              }}
                            >
                              {get(row, 'status')}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              style={{
                                background: 'green',
                                color: '#fff',
                              }}
                            >
                              {get(row, 'status')}
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="left"> {moment(get(row, 'createdAt')).format('yyyy-MM-DD')}</TableCell>
                        <TableCell align="left">
                          {' '}
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: '#800000',
                              color: 'white',
                            }}
                            onClick={() => {
                              let link =
                                config.WEB_LINK +
                                'dashboard/courseDetailsAndEnrollments/?courseId=' +
                                get(row, 'courseId._id', '');

                              window.open(link, '_blank');
                            }}
                          >
                            View Course
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={paymentCount}
            rowsPerPage={paymentRowsPerPage}
            page={paymentPage}
            onPageChange={handleChangePagePayment}
            onRowsPerPageChange={handleChangeRowsPerPagePayment}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              Payment History
            </Typography>

            {/*{editable && (*/}
            {/*  <Button*/}
            {/*    variant="contained"*/}
            {/*    onClick={(e) => {*/}
            {/*      console.log(e);*/}
            {/*    }}*/}
            {/*    style={{ color: '#FFFFFF', backgroundColor: '#880ED4', marginRight: 15 }}*/}
            {/*  >*/}
            {/*    DUE = {get(studentInfo, 'courseFee', 0) - totalCollected} BDT*/}
            {/*  </Button>*/}
            {/*)}*/}

            {editable && enrolledCourses.length && (
              <Button
                variant="contained"
                onClick={(e) => {
                  handlePaymentEdit(null);
                }}
                style={{ color: '#FFFFFF', backgroundColor: '#880ED4', marginRight: 15 }}
              >
                ADD NEW PAYMENT
              </Button>
            )}
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD_PAYMENT} rowCount={paymentList.length} />
                <TableBody>
                  {paymentList.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'amount', 0)}</TableCell>
                        <TableCell align="left"> {get(row, 'courseId.title', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'type', '')}</TableCell>
                        <TableCell align="left">
                          {get(row, 'collectionForMonth', '') + ' - ' + get(row, 'year', '')}
                        </TableCell>
                        <TableCell align="left"> {get(row, 'paymentMethod', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'paymentDetails', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'verifiedBy.phone', '')}</TableCell>
                        <TableCell align="left"> {moment(get(row, 'createdAt')).format('yyyy-MM-DD')}</TableCell>
                        <TableCell align="left"> {get(row, 'paymentCollected') ? 'Already Collected' : 'Not Collected'}</TableCell>
                        <TableCell align="left">
                          {get(row, 'isActive') === false ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              INACTIVE
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              style={{
                                background: 'green',
                                color: '#fff',
                              }}
                            >
                              ACTIVE
                            </Button>
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {!get(row, 'paymentCollected', true) &&
                            (<Button
                              variant="contained"
                              onClick={() => handlePaymentEdit(row)}
                              style={{ color: '#FFFFFF', backgroundColor: '#880ED4' }}
                            >
                              <Edit style={{ color: '#FFFFFF', marginRight: 5 }} />
                              EDIT
                            </Button>)
                          }
                          {get(row, 'isActive') === true && (
                            <Button
                              variant="contained"
                              style={{ marginTop: '1rem' }}
                              component={RouterLink}
                              to={'/invoice/?invoiceId=' + get(row, '_id')}
                            >
                              PRINT
                            </Button>
                          )}
                          {get(row, 'archive', []).length > 0 ? (
                            <Button
                              variant="contained"
                              onClick={() => handlePaymentHistory(row, get(row, '_id', ''))}
                              style={{ color: '#FFFFFF', backgroundColor: '#880ED4', marginTop: '.5rem' }}
                            >
                              HISTORY
                            </Button>
                          ) : (
                            console.log('N/A')
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={paymentCount}
            rowsPerPage={paymentRowsPerPage}
            page={paymentPage}
            onPageChange={handleChangePagePayment}
            onRowsPerPageChange={handleChangeRowsPerPagePayment}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>

        {/* Batch */}
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              Exam Performance
            </Typography>

            {editable && (
              <Button
                variant="contained"
                onClick={(e) => {
                  downloadExcel();
                }}
                style={{ color: 'white', backgroundColor: 'blue', width: '20%', marginRight: 15 }}
              >
                <Downloading style={{ color: 'white', marginRight: 5 }} />
                DOWNLOAD EXCEL
              </Button>
            )}
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={examList.length} />
                <TableBody>
                  {examList.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'examName', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'session', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'maxMarks', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'myMarks', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'totalMarks', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'correct', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'incorrect', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'skipped', '')}</TableCell>
                        <TableCell align="left"> {moment(get(row, 'date')).format('yyyy-MM-DD ~ hh:mm A')}</TableCell>
                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to={'/dashboard/examResult/?examId=' + get(row, '_id')}
                            >
                              VIEW EXAM
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>
      </div>
    );
  };

  return (
    <div>
      {studentId && renderBasicCard()}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="firstName"
              label="First name"
              value={get(studentInfo, 'firstname', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('firstname', e);
              }}
              required
            />
            <TextField
              name="lastName"
              label="Last name"
              value={get(studentInfo, 'lastname', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('lastname', e);
              }}
              required
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="email"
              label="Email address"
              value={get(studentInfo, 'email', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('email', e);
              }}
            />
            <TextField
              name="username"
              label="User Name"
              value={get(studentInfo, 'username', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('username', e);
              }}
              required
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="phone"
              label="Student Phone"
              value={get(studentInfo, 'phone', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('phone', e);
              }}
              required
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <TextField
              name="guardianSms"
              label="Guardian SMS"
              value={get(studentInfo, 'guardianSMS', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('guardianSMS', e);
              }}
              required
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            {get(studentInfo, 'institutionId.institutionName', '') ? (
              <FormControl fullWidth required style={{ width: '75%' }}>
                <InputLabel id="sessiom-label">Institution Name</InputLabel>
                <Select
                  id="session-label"
                  disabled={get(adminData, 'roleSet', []).includes('SWITCH_INSTITUTE_OF_STUDENTS') ? false : true}
                  value={get(studentInfo, 'institutionId.institutionName', '')}
                  label="Institution Name"
                  onChange={(e) => {
                    handleChange('institutionId', e);
                  }}
                >
                  {institutes.map((institution) => (
                    <MenuItem key={institution._id} value={institution.institutionName}>
                      {institution.institutionName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              console.log('')
            )}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Valid Till"
                name="validTill"
                value={moment(get(studentInfo, 'validTill')).format('yyyy-MM-DD')}
                onChange={(newValue) => {
                  setStudentInfo({ ...studentInfo, validTill: new Date(newValue) });
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} />}
                style={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="address"
              label="College & District"
              value={get(studentInfo, 'address', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('address', e);
              }}
              required
            />
            <TextField
              name="roll"
              label="Unique Roll Number"
              value={get(studentInfo, 'roll', '')}
              style={{ width: '50%' }}
              onChange={(e) => {
                handleChange('roll', e);
              }}
              required
              onKeyPress={(event) => {
                if (/[A-Z0-9]/.test(event.key)) {
                } else {
                  event.preventDefault();
                }
              }}
            />

            {/*<TextField*/}
            {/*  name="courseFee"*/}
            {/*  label="Course Fee"*/}
            {/*  value={get(studentInfo, 'courseFee', 0)}*/}
            {/*  style={{ width: '50%' }}*/}
            {/*  onChange={(e) => {*/}
            {/*    handleChange('courseFee', e);*/}
            {/*  }}*/}
            {/*  required*/}
            {/*  onKeyPress={(event) => {*/}
            {/*    if (!/[0-9]/.test(event.key)) {*/}
            {/*      event.preventDefault();*/}
            {/*    }*/}
            {/*  }}*/}
            {/*/>*/}

            {adminStatus == 'super-admin' ? (
              <FormControl fullWidth required style={{ width: '50%' }}>
                <InputLabel id="av-label">Publicly Available</InputLabel>
                <Select
                  id="av-label"
                  value={get(studentInfo, 'isPublic', 'true')}
                  label="Publicly Available"
                  onChange={(e) => {
                    handleChange('isPublic', e);
                  }}
                >
                  {activeStatus.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              console.log('Editing Student')
            )}
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl fullWidth required style={{ width: '50%' }}>
              <InputLabel id="sessiom-label">Session</InputLabel>
              <Select
                id="session-label"
                value={get(studentInfo, 'session', 2024)}
                label="Session"
                onChange={(e) => {
                  handleChange('session', e);
                }}
              >
                {sessions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth required style={{ width: '100%' }}>
              <InputLabel id="demo-multiple-chip-label">Branch</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={get(studentInfo, 'branchId', [])}
                onChange={(e) => {
                  handleChange2('branchId', e);
                }}
                input={<OutlinedInput id="select-multiple-chip" label="Branch" />}
                MenuProps={MenuProps}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={typeof value === 'object' ? get(value, '_id') : value}
                        label={getName('branch', value)}
                        onDelete={() => {
                          deleteChip('branchId', value);
                        }}
                        style={{ zIndex: 10000 }}
                      />
                    ))}
                  </Box>
                )}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.branchName} value={branch.id}>
                    {branch.branchName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth required style={{ width: '100%' }}>
              <InputLabel id="batch-level">Batch</InputLabel>
              <Select
                style={{ width: '100%' }}
                labelId="batch-level"
                id="batch-level"
                multiple
                value={get(studentInfo, 'batchId', [])}
                onChange={(e) => {
                  handleChange2('batchId', e);
                }}
                input={<OutlinedInput id="select-multiple-chip-batch" label="Batch" />}
                MenuProps={MenuProps}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={typeof value === 'object' ? get(value, '_id') : value}
                        label={getName('batch', value)}
                        onDelete={() => {
                          deleteChip('batchId', value);
                        }}
                        style={{ zIndex: 10000 }}
                      />
                    ))}
                  </Box>
                )}
              >
                {batches.map((batch) => (
                  <MenuItem key={batch.batchName} value={batch.id}>
                    {batch.batchName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <TextField name="batch" label="Batch" defaultValue="test" style={{width : "100%"}} required/> */}
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="fathersName"
              label="Father's Name"
              value={get(studentInfo, 'fathersName', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('fathersName', e);
              }}
            />
            <TextField
              name="fathersPhone"
              label="Father's Phone"
              value={get(studentInfo, 'fathersMobileNumber', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('fathersMobileNumber', e);
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              name="mothersName"
              label="Mother's Name"
              value={get(studentInfo, 'mothersName', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('mothersName', e);
              }}
            />
            <TextField
              name="mothersPhone"
              label="Mother's Phone"
              value={get(studentInfo, 'mothersMobileNumber', '')}
              style={{ width: '100%' }}
              onChange={(e) => {
                handleChange('mothersMobileNumber', e);
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Stack>

          {editable ? (
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              onClick={(e) => {
                studentId
                  ? handleStudentUpdate(studentId, {
                      ...studentInfo,
                      userData: {
                        username: get(studentInfo, 'username'),
                        roll: get(studentInfo, 'roll'),
                        phone: get(studentInfo, 'phone'),
                      },
                    })
                  : handleStudentCreation(studentInfo);
              }}
            >
              ADD / EDIT STUDENT
            </LoadingButton>
          ) : (
            <div></div>
          )}
        </Stack>

        {alertContent !== '' ? (
          <div>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
              <Alert variant="filled" severity="error">
                {alertContent.split('->')[0]}
              </Alert>

              <Button variant="contained" color="error" onClick={getViewStudentUrlOnError}>
                VIEW DUPLICATE STUDENT
              </Button>
            </Stack>
          </div>
        ) : (
          <div />
        )}
      </FormProvider>

      {studentId && renderExcel()}

      {/* PAYMENT MODAL */}
      <div>
        <Dialog open={paymentModal} onClose={() => setPaymentModal(false)}>
          <DialogTitle>Payment Information</DialogTitle>
          <DialogContent>
            <TextField
              name="amount"
              label="Payment Amount"
              style={{ width: '100%', marginTop: 20 }}
              value={get(paymentPopUpData, 'amount', '')}
              onChange={(e) => {
                handlePaymentDataChange('amount', e);
              }}
              required
            />

            <FormControl fullWidth required style={{ width: '100%', marginTop: 20 }}>
              <InputLabel id="paymentMethod">Payment</InputLabel>
              <Select
                // labelId="session"
                id="paymentMethod"
                value={get(paymentPopUpData, 'paymentMethod', 2024)}
                label="Payment Method"
                onChange={(e) => {
                  handlePaymentDataChange('paymentMethod', e);
                }}
              >
                {paymentMethods.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              name="paymentDetails"
              label="Payment Details"
              style={{ width: '100%', marginTop: 20 }}
              value={get(paymentPopUpData, 'paymentDetails', '')}
              onChange={(e) => {
                handlePaymentDataChange('paymentDetails', e);
              }}
              required
            />

            <TextField
              name="note"
              label="Note (please tell reason when you delete it)"
              style={{ width: '100%', marginTop: 20 }}
              value={get(paymentPopUpData, 'note', '')}
              onChange={(e) => {
                handlePaymentDataChange('note', e);
              }}
              required
            />

            <TextField
              name="createdAt"
              label="Payment Date"
              style={{ width: '100%', marginTop: 20 }}
              value={moment(get(paymentPopUpData, 'createdAt')).format('DD/MM/YYYY <--> hh:mm A')}
              onChange={(e) => {
                handlePaymentDataChange('createdAt', e);
              }}
              required
            />

            <FormControl fullWidth required style={{ width: '100%', marginTop: 20 }}>
              <InputLabel id="course-label">Payment for the course</InputLabel>
              <Select
                // labelId="session"
                id="course-label"
                value={get(paymentPopUpData, 'courseId', '')}
                label="Course"
                onChange={(e) => {
                  handlePaymentDataChange('courseId', e);
                }}
              >
                {enrolledCourses.map((course) => (
                  <MenuItem key={course.courseId.title} value={course.courseId._id}>
                    {course.courseId.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FormControl fullWidth required style={{ width: '100%', marginTop: 20 }}>
                <InputLabel id="isActive">Payment For the Month</InputLabel>
                <Select
                  // labelId="session"
                  id="collectionForMonth-P"
                  value={get(paymentPopUpData, 'collectionForMonth', true)}
                  label="collectionForMonth-P"
                  onChange={(e) => {
                    handlePaymentDataChange('collectionForMonth', e);
                  }}
                >
                  {paymentMonth.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth required style={{ width: '100%', marginTop: 20 }}>
                <InputLabel id="isActive">Payment Year</InputLabel>
                <Select
                  // labelId="session"
                  id="year-P"
                  value={get(paymentPopUpData, 'year', '')}
                  label="Active Status"
                  onChange={(e) => {
                    handlePaymentDataChange('year', e);
                  }}
                >
                  {sessions.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth required style={{ width: '100%', marginTop: 20 }}>
                <InputLabel id="isActive">Active Status</InputLabel>
                <Select
                  // labelId="session"
                  id="isActive"
                  value={get(paymentPopUpData, 'isActive', true)}
                  label="Active Status"
                  onChange={(e) => {
                    handlePaymentDataChange('isActive', e);
                  }}
                >
                  {activeStatus.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              onClick={setPaymentInDatabase}
              style={{ color: 'white', backgroundColor: 'blue' }}
            >
              ADD / EDIT
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* HISTORY MODAL */}
      <div>
        <Dialog open={paymentHistoryModal} onClose={() => setPaymentHistoryModal(false)}  sx={{
          "& .MuiDialog-paper": { width: "80%", maxWidth: "200%" } // Custom width
        }}>
          <DialogTitle>Payment History Tracking</DialogTitle>
          <DialogContent>
            <TableContainer style={{ width: '100%', marginTop: 20 }}>
              <Table>
                <UserListHead headLabel={TABLE_HISTORY} rowCount={get(paymentHistory, 'data', [{}]).length} />
                <TableBody>
                  {get(paymentHistory, 'data', [{}])
                    .map((row, rowIndex) => {
                      const isLastRow = rowIndex === get(paymentHistory, 'data', [{}]).length - 1;
                      return (
                        <TableRow hover key={get(row, '_id', '')} tabIndex={-1} sx={isLastRow ? { border: "5px solid green" } : {}}>
                          <TableCell align="left">
                            <span>{rowIndex + 1}</span>
                          </TableCell>
                          <TableCell align="left">
                            <span style={getBackgroundStylingOfPaymentHistoryCell(row, 'prevAmount', rowIndex)}>
                              {get(row, 'prevAmount', '')}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span style={getBackgroundStylingOfPaymentHistoryCell(row, 'previsActive', rowIndex)}>
                              {get(row, 'previsActive', '') ? 'Active' : 'InActive'}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span style={getBackgroundStylingOfPaymentHistoryCell(row, 'prevPaymentMethod', rowIndex)}>
                              {get(row, 'prevPaymentMethod', '')}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span style={getBackgroundStylingOfPaymentHistoryCell(row, 'prevAdminId.phone', rowIndex)}>
                              {get(row, 'prevAdminId.phone', '')}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span style={getBackgroundStylingOfPaymentHistoryCell(row, 'adminId.phone', rowIndex)}>
                              {get(row, 'adminId.phone', '')}
                            </span>
                          </TableCell>
                          <TableCell align="left"> {moment(get(row, 'editingDate')).format('yyyy-MM-DD')}</TableCell>
                          <TableCell align="left">
                            <span style={getBackgroundStylingOfPaymentHistoryCell(row, 'note', rowIndex)}>
                              {get(row, 'note', '')}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span style={getBackgroundStylingOfPaymentHistoryCell(row, 'paymentDetails', rowIndex)}>
                              {get(row, 'paymentDetails', '')}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span style={getBackgroundStylingOfPaymentHistoryCell(row, 'type', rowIndex)}>
                              {get(row, 'type', '')}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span style={getBackgroundStylingOfPaymentHistoryCell(row, 'collectionForMonth', rowIndex)}>
                              {get(row, 'collectionForMonth', '')}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span style={getBackgroundStylingOfPaymentHistoryCell(row, 'year', rowIndex)}>
                              {get(row, 'year', '')}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            <span style={getBackgroundStylingOfPaymentHistoryCell(row, 'courseId.title', rowIndex)}>
                              {get(row, 'courseId.title', '')}
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={removePaymentFromHistory}
              style={{ color: 'white', backgroundColor: 'blue' }}
            >
              REMOVE HISTORY
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
