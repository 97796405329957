import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// APIS
// material
import {
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import { UserListHead } from '../../sections/@dashboard/user';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { courseTypes, paymentTypes } from '../../util/helpers';
import DialogActions from '@mui/material/DialogActions';
import { Edit } from '@mui/icons-material';
import { createCourse, getFilteredCoursesAdmin } from '../../axiosLib/services/course/admin';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD_COURSE = [
  { id: 'name', label: 'Course Title', alignRight: false },
  { id: 'code', label: 'Discounted Price', alignRight: false },
  { id: 'updatedAt', label: 'Duration', alignRight: false },
  { id: 'registered_at', label: 'Course Type', alignRight: false },
  { id: 'teachers', label: 'Course Teachers', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'paymentType', label: 'Payment Type', alignRight: false },
  { id: 'VIEW', label: 'VIEW', alignRight: false },
  { id: 'EDIT', label: 'EDIT', alignRight: false },
];

export default function CourseList() {
  const navigate = useNavigate();

  const [courseList, setCourseList] = useState([]);

  const [courseModal, setCourseModal] = useState(false);

  const [courseData, setCourseData] = useState({});

  const [activeStatus, setActiveStatus] = useState({});

  const handleCourseEdit = (row) => {
    setCourseData(row);

    setCourseModal(true);
  };

  const insetCourse = () => {
    createCourse(courseData, (error, data) => {
      if (!error) {
        // console.log(data);

        window.location.reload(false);
      } else {
        console.log(error);
      }
    });
  };

  const handleCourseDataChange = (fieldName, event) => {
    // console.log(event.target.value);
    setCourseData({ ...courseData, [fieldName]: event.target.value });
  };

  const getCourses = () => {
    let temp = {
      status: activeStatus.status ?? 'Active',
    };

    try {
      getFilteredCoursesAdmin(temp, (error, data) => {
        if (!error) {
          setCourseList(get(data, 'courses', []));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    getCourses();
  }, [activeStatus]);

  return (
    <Page title="Institute Details">
      <Container>
        {/* Course */}
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              All Available Course List
            </Typography>
            <FormControl fullWidth required style={{ width: '30%', marginLeft: 15, marginTop: '15px' }}>
              <InputLabel id="sessiom-label">Active Status</InputLabel>
              <Select
                id="active-status-label"
                defaultValue={get(activeStatus, 'status', 'Active')}
                label="Session"
                onChange={(e) => {
                  setActiveStatus({ ...activeStatus, ['status']: e.target.value });
                }}
              >
                {['Active', 'Not Active'].map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleCourseEdit({})}
            >
              Add New Course
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD_COURSE} rowCount={courseList.length} />
                <TableBody>
                  {courseList.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'title', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'discountedPrice', '0')}</TableCell>
                        <TableCell align="left"> {get(row, 'duration', '0')} Days</TableCell>
                        <TableCell align="left"> {get(row, 'type', '')} Days</TableCell>
                        <TableCell align="left"> {get(row, 'teachers', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'publishType', 'Internal')}</TableCell>
                        <TableCell align="left"> {get(row, 'paymentType', '')}</TableCell>
                        <TableCell align="left">
                          {' '}
                          {get(row, 'isActive', true) ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'green',
                                color: '#fff',
                              }}
                            >
                              Active
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              Not Active
                            </Button>
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to={'/dashboard/courseDetailsAndEnrollments/?courseId=' + get(row, '_id')}
                            >
                              VIEW DETAILS
                            </Button>
                          }
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              onClick={() => handleCourseEdit(row)}
                              style={{ color: '#880ED4', backgroundColor: 'white' }}
                            >
                              <Edit style={{ color: '#880ED4', marginRight: 5 }} /> EDIT
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>

        {/* COURSE MODAL*/}
        <div>
          <Dialog open={courseModal} onClose={() => setCourseModal(false)} fullWidth={true}>
            <DialogTitle>Add/Edit Course</DialogTitle>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              {' '}
            </Stack>
            <DialogContent>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingBottom={2}>
                <TextField
                  name="name"
                  label="Course Title"
                  style={{ width: '100%' }}
                  value={get(courseData, 'title', '')}
                  onChange={(e) => {
                    handleCourseDataChange('title', e);
                  }}
                  required
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingBottom={2}>
                <TextField
                  name="subjectCode"
                  label="Description"
                  style={{ width: '100%' }}
                  value={get(courseData, 'description', '')}
                  onChange={(e) => {
                    handleCourseDataChange('description', e);
                  }}
                  required
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingBottom={2}>
                <TextField
                  name="c-m-price"
                  label="Main Price"
                  style={{ width: '100%' }}
                  value={get(courseData, 'price', '0')}
                  onChange={(e) => {
                    handleCourseDataChange('price', e);
                  }}
                  required
                />

                <TextField
                  name="subjectCode"
                  label="Discounted Price"
                  style={{ width: '100%' }}
                  value={get(courseData, 'discountedPrice', '0')}
                  onChange={(e) => {
                    handleCourseDataChange('discountedPrice', e);
                  }}
                  required
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingBottom={2}>
                <TextField
                  name="subjectCode"
                  label="Course Duration in Days"
                  style={{ width: '50%' }}
                  value={get(courseData, 'duration', '0')}
                  onChange={(e) => {
                    handleCourseDataChange('duration', e);
                  }}
                  required
                />

                <TextField
                  name="subjectCode"
                  label="Course Duration in Bangla"
                  style={{ width: '100%' }}
                  value={get(courseData, 'time', '0')}
                  onChange={(e) => {
                    handleCourseDataChange('time', e);
                  }}
                  required
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingBottom={2}>
                <TextField
                  name="coverPhoto"
                  label="Cover Photo Link"
                  style={{ width: '100%' }}
                  value={get(courseData, 'image', '0')}
                  onChange={(e) => {
                    handleCourseDataChange('image', e);
                  }}
                  required
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingBottom={2}>
                <TextField
                  name="teachers"
                  label="Course Teachers"
                  style={{ width: '100%' }}
                  value={get(courseData, 'teachers', '')}
                  onChange={(e) => {
                    handleCourseDataChange('teachers', e);
                  }}
                  required
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingBottom={2}>
                <FormControl fullWidth required>
                  <InputLabel id="sessiom-label">Active</InputLabel>
                  <Select
                    id="session-label"
                    value={get(courseData, 'isActive', 'false')}
                    label="Publishd Course"
                    onChange={(e) => {
                      handleCourseDataChange('isActive', e);
                    }}
                  >
                    {['true', 'false'].map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth required>
                  <InputLabel id="Internal-sessiom-label">Publish Type</InputLabel>
                  <Select
                    id="Internal-session-label"
                    value={get(courseData, 'publishType', 'Internal')}
                    label="Publishd Course"
                    onChange={(e) => {
                      handleCourseDataChange('publishType', e);
                    }}
                  >
                    {['Internal', 'Public'].map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} paddingBottom={2}>
                <FormControl fullWidth required>
                  <InputLabel id="sessiom-label">Course Type</InputLabel>
                  <Select
                    id="c-type"
                    value={get(courseData, 'type', '')}
                    label="Course Type"
                    onChange={(e) => {
                      handleCourseDataChange('type', e);
                    }}
                  >
                    {courseTypes.map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth required style={{ width: '100%' }}>
                  <InputLabel id="isActive">Payment Type</InputLabel>
                  <Select
                    // labelId="session"
                    id="paymentTypes"
                    value={get(courseData, 'paymentType', paymentTypes[0])}
                    label="Active Status"
                    onChange={(e) => {
                      handleCourseDataChange('paymentType', e);
                    }}
                  >
                    {paymentTypes.map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button variant="contained" onClick={insetCourse} style={{ color: 'white', backgroundColor: 'blue' }}>
                ADD / EDIT Course
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </Page>
  );
}
