import { Box, Container } from '@mui/material';
import { Icon } from '@iconify/react';

export default function PageFooter() {
  return (
    <Box sx={{ backgroundColor: '#0c366d', width: '100%', color: 'white' }}>
      <Container sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'flex-start', flexWrap: 'wrap' }}>
        <div style={{ marginRight: '1%', marginBottom: '20px', marginTop: '20px' }}>
          <h3>
            <b>Contact Us</b>
          </h3>
          <h6>
            <Icon icon="gg:phone" style={{ fontSize: '25px' }} /> +8801742-481277
          </h6>
          <h6>
            <Icon icon="mdi:gmail" style={{ fontSize: '25px' }} /> drasifmedicalprivatebatch@gmail.com
          </h6>
          <h6>
            <Icon icon="zondicons:location" style={{ fontSize: '25px' }} /> ফুল মার্কেট মোড়, খুলনা
          </h6>
          <h6>
            <Icon icon="zondicons:location" style={{ fontSize: '25px' }} /> মুজুগুন্নী আবাসিক এলাকা, বয়রা, খুলনা
          </h6>
        </div>
        <div style={{ marginRight: '1%', marginBottom: '20px', marginTop: '20px' }}>
          <h3>
            <b>About Us</b>
          </h3>
          <h6>
            <Icon icon="ant-design:facebook-filled" style={{ fontSize: '25px' }} /> Facebook
          </h6>
          <h6>
            <Icon icon="grommet-icons:youtube" style={{ fontSize: '25px' }} /> Youtube
          </h6>
        </div>
        <div style={{ marginRight: '1%', marginBottom: '20px', marginTop: '20px' }}>
          <h3>
            <b>Legal</b>
          </h3>
          <h6>
            <Icon icon="mdi:legal" style={{ fontSize: '25px' }} /> Terms & Conditions
          </h6>
          <h6>
            <Icon icon="ic:sharp-privacy-tip" style={{ fontSize: '25px' }} /> Privacy Policy
          </h6>
          <h6>
            <Icon icon="ic:sharp-security" style={{ fontSize: '25px' }} />
            Security Policy
          </h6>
        </div>
      </Container>
    </Box>
  );
}
