import { get } from 'lodash';
// @mui
import { useTheme } from '@mui/material/styles';
import { Container, Grid } from '@mui/material';

//API
import { getBatchSummary, getUserCount, getUserRatioPie } from 'src/axiosLib/services/user/admin';
import { getRemainingSMS } from 'src/axiosLib/services/sms/admin';

// components
import Page from '../../components/Page';
// sections
import { AppCurrentVisits, AppWebsiteVisits, AppWidgetSummary } from '../../sections/@dashboard/app';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCpu } from 'src/axiosLib/services/institution/admin';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [cpuUsage, setCpuUsage] = useState(5);

  const [batchSummary, setBatchSummary] = useState([]);

  const [sms, setRemainingSms] = useState([]);

  const [users, setTotalUsers] = useState([]);

  const [userRatio, setuserRatio] = useState([]);

  const SEC = 3000;

  useEffect(() => {
    var timesRun = 0;

    const interval = setInterval(() => {
      timesRun += 1;

      if (timesRun === 100) {
        clearInterval(interval);
      }

      getCpu((error, data) => {
        if (!error) {
          setCpuUsage(Number(data.msg));
        } else {
          console.log(error);
        }
      });
    }, SEC);
  }, []);

  const getStats = () => {
    try {
      getBatchSummary((error, data) => {
        if (!error) {
          setBatchSummary(data);
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const remainingSms = () => {
    try {
      getRemainingSMS((error, data) => {
        if (!error) {
          setRemainingSms(data);
        } else {
          navigate('/login', { replace: true });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const totalUsers = () => {
    try {
      getUserCount((error, data) => {
        if (!error) {
          setTotalUsers(data);
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getUserRatio = () => {
    try {
      getUserRatioPie((error, data) => {
        if (!error) {
          setuserRatio(data.data);
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStats();
    remainingSms();
    totalUsers();
    getUserRatio();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="SMS Balance" total={parseInt(sms)} icon={'subway:sms-3'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Students"
              total={parseInt(users)}
              color="info"
              icon={'fa6-solid:users-rays'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Institute Change Req"
              total={get(userRatio[2], 'value', 0)}
              color="warning"
              icon={'mdi:register'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="SERVER LOAD in %" total={cpuUsage} color="error" icon={'mdi:server-security'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Batch wise student count"
              // subheader="(+43%) than last year"
              chartLabels={batchSummary.batchName}
              chartData={[
                {
                  name: '',
                  type: 'column',
                  fill: 'solid',
                  // data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                  data: batchSummary.students,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="All Student Summary"
              chartData={userRatio}
              chartColors={[
                theme.palette.chart.green[255],
                // theme.palette.primary.main,
                theme.palette.chart.violet[0],
                theme.palette.chart.red[0],
                // theme.palette.chart.yellow[0],
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
