import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Alert,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider } from '../../../components/hook-form';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import { get, set } from 'lodash';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { sessions } from 'src/util/helpers';
import { getAllBranches } from 'src/axiosLib/services/branch/admin';
import { getAllBatches } from 'src/axiosLib/services/batch/admin';
import { getUserDetail } from 'src/axiosLib/services/user/admin';
import { getAllSubjects } from 'src/axiosLib/services/subject/admin';
import { setExam } from 'src/axiosLib/services/exam/admin';
import { adminLevel, getAllInstitutions } from '../../../axiosLib/services/institution/admin';
import { getAllCoursesAdmin } from '../../../axiosLib/services/course/admin';

// ----------------------------------------------------------------------

export default function ExamView() {
  const navigate = useNavigate();

  const [examInfo, setexamInfo] = useState({
    validTill: moment().format('DD/MM/YYYY'),
    startTime: moment().format('yyyy-MM-DD hh:mm A'),
    endTime: moment().format('yyyy-MM-DD hh:mm A'),
    answerPublishTime: moment().format('yyyy-MM-DD hh:mm A'),
    session: 2024,
  });

  const [branches, setBranches] = useState([]);
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [alertContent, setAlertContent] = useState('');
  const [studentId, setStudentId] = useState(null);
  const [adminData, setAdminData] = useState({});

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    navigate('/dashboard/user', { replace: true });
  };

  useEffect(() => {
    getAllSubjects(1000, 0, (err, data) => {
      if (!err) {
        const allsubjects = data.subjects;
        const subjectDetails = allsubjects.map((subject) => {
          return { id: subject._id, name: subject.name + ' ' + subject.subjectCode };
        });
        setSubjects(subjectDetails);
        // console.log(subjectDetails);
      }
    });

    getAllCoursesAdmin((error, data) => {
      if (!error) {
        setCourses(get(data, 'courses', []));
      }
    });

    getAllBranches(100, 0, (err, data) => {
      if (!err) {
        const branches = data.branches;
        const branchDetails = branches.map((branch) => {
          return { id: branch._id, branchName: branch.branchName };
        });
        setBranches(branchDetails);
        // console.log(branchDetails);
      }

      adminLevel((error, data) => {
        if (!error) {
          setAdminData(get(data, 'admin'));
        } else {
          console.log(error);
        }
      });
    });

    getAllBatches(1000, 0, (err, data) => {
      if (!err) {
        const batches = data.batches;
        const batchDetails = batches.map((batch) => {
          return { id: batch._id, batchName: batch.batchName };
        });
        setBatches(batchDetails);
        // console.log(batchDetails);
      }
    });

    getAllInstitutions((error, data) => {
      if (!error) {
        setInstitutions(data);
      } else {
        console.log(error);
      }
    });
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const stdId = urlParams.get('std');

    if (branches.length && batches.length) {
      if (stdId) {
        getUserDetail(stdId, (err, data) => {
          if (!err) {
            setexamInfo(data);
            setStudentId(stdId);
          }
        });
      }
    }
  }, [branches, batches]);

  useEffect(() => {
    // console.log(examInfo);
  }, [examInfo]);

  const handleChange = (fieldName, event) => {
    // console.log(event.target.value);
    if (fieldName === 'validTill') {
      setexamInfo({ ...examInfo, [fieldName]: moment(event.target.value).format('DD/MM/YYYY') });
    } else {
      setexamInfo({ ...examInfo, [fieldName]: event.target.value });
    }
  };

  const handleChange2 = (fieldName, event) => {
    const {
      target: { value },
    } = event;
    const branchNames = typeof value === 'string' ? value.split(',') : value;
    setexamInfo({
      ...examInfo,
      [fieldName]: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const handleChange3 = (fieldName, event) => {
    const {
      target: { value },
    } = event;

    setexamInfo({
      ...examInfo,
      [fieldName]: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const handleCreateOrEdit = () => {
    // console.log(examInfo)

    // setexamInfo({...examInfo , totalMarks : get(examInfo , 'numberOfQuestions' , 0)})
    const val = get(examInfo, 'numberOfQuestions', 0) * get(examInfo, 'marksPerQuestion', 0);
    set(examInfo, 'totalMarks', val);

    setExam(examInfo, (err, data) => {
      if (!err) {
        navigate('/dashboard/onlineExam', { replace: true });
      } else {
        console.log(err);
      }
    });
  };

  const deleteChip = (fieldName, chipToDelete) => {
    const values = get(examInfo, fieldName, []);
    setexamInfo({
      ...examInfo,
      [fieldName]: values.filter((value) => {
        return value !== chipToDelete;
      }),
    });
  };

  const getName = (fieldName, idTOSearch) => {
    if (typeof idTOSearch === 'object') {
      idTOSearch = get(idTOSearch, '_id');
    }
    if (fieldName === 'branch') {
      const selectedBranch = branches.filter((branch) => {
        return branch.id === idTOSearch;
      });

      return selectedBranch[0] ? selectedBranch[0].branchName : null;
    } else if (fieldName === 'batch') {
      const selectedBatch = batches.filter((batch) => {
        return batch.id === idTOSearch;
      });

      return selectedBatch[0] ? selectedBatch[0].batchName : null;
    } else if (fieldName === 'subject') {
      const selectedSubject = subjects.filter((subject) => {
        return subject.id === idTOSearch;
      });

      return selectedSubject[0] ? selectedSubject[0].name : null;
    } else if (fieldName === 'course') {
      const selectedCourse = courses.filter((course) => {
        return course._id === idTOSearch;
      });

      return selectedCourse[0] ? selectedCourse[0].title : null;
    } else if (fieldName === 'sharedInstitution') {
      const selectedInstitutes = institutions.filter((course) => {
        return course._id === idTOSearch;
      });

      return selectedInstitutes[0] ? selectedInstitutes[0].institutionName : null;
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
            name="name"
            label="Exam Name"
            value={get(examInfo, 'name', '')}
            style={{ width: '100%' }}
            onChange={(e) => {
              handleChange('name', e);
            }}
            required
          />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl fullWidth required>
            <InputLabel id="demo-multiple-chip-label">Online Exam Branch</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={get(examInfo, 'branchId', [])}
              onChange={(e) => {
                handleChange2('branchId', e);
              }}
              input={<OutlinedInput id="select-multiple-chip" label="Branch" />}
              MenuProps={MenuProps}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={typeof value === 'object' ? get(value, '_id') : value}
                      label={getName('branch', value)}
                      onDelete={() => {
                        deleteChip('branchId', value);
                      }}
                      style={{ zIndex: 10000 }}
                    />
                  ))}
                </Box>
              )}
            >
              {branches.map((branch) => (
                <MenuItem key={branch.branchName} value={branch.id}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth required>
            <InputLabel id="batch-level">Online Exam Batch</InputLabel>
            <Select
              labelId="batch-level"
              id="batch-level"
              multiple
              value={get(examInfo, 'batchId', [])}
              onChange={(e) => {
                handleChange2('batchId', e);
              }}
              input={<OutlinedInput id="select-multiple-chip-batch" label="Batch" />}
              MenuProps={MenuProps}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={getName('batch', value)}
                      onDelete={() => {
                        deleteChip('batchId', value);
                      }}
                      style={{ zIndex: 10000 }}
                    />
                  ))}
                </Box>
              )}
            >
              {batches.map((batch) => (
                <MenuItem key={batch.batchName} value={batch.id}>
                  {batch.batchName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
            name="numberOfQuestions"
            label="Total Questions"
            value={get(examInfo, 'numberOfQuestions', '')}
            style={{ width: '100%' }}
            onChange={(e) => {
              handleChange('numberOfQuestions', e);
            }}
            required
          />

          <TextField
            name="marksPerQuestion"
            label="Marks Per Question"
            value={get(examInfo, 'marksPerQuestion', '')}
            style={{ width: '100%' }}
            onChange={(e) => {
              handleChange('marksPerQuestion', e);
            }}
          />

          <TextField
            name="negativeMarks"
            label="Negative Marks Per Question"
            value={get(examInfo, 'negativeMarks', '')}
            style={{ width: '100%' }}
            onChange={(e) => {
              handleChange('negativeMarks', e);
            }}
            required
          />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl fullWidth required>
            <InputLabel id="course-l-level">Course</InputLabel>
            <Select
              labelId="course-l-level"
              id="course-level"
              multiple
              value={get(examInfo, 'courseId', [])}
              onChange={(e) => {
                handleChange3('courseId', e);
              }}
              input={<OutlinedInput id="select-multiple-chip-course" label="Course" />}
              MenuProps={MenuProps}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={getName('course', value)}
                      onDelete={() => {
                        deleteChip('courseId', value);
                      }}
                      style={{ zIndex: 10000 }}
                    />
                  ))}
                </Box>
              )}
            >
              {courses.map((subject) => (
                <MenuItem key={subject._id} value={subject._id}>
                  {subject.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        {/*</Stack>*/}

        {/*<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>*/}
          <FormControl fullWidth required>
            <InputLabel id="sharedInstitution-l-level">Exam is also available to:</InputLabel>
            <Select
              labelId="sharedInstitution-l-level"
              id="sharedInstitution-level"
              multiple
              value={get(examInfo, 'sharedInstitution', [])}
              onChange={(e) => {
                handleChange3('sharedInstitution', e);
              }}
              input={<OutlinedInput id="select-multiple-chip-sharedInstitution" label="Shared Institutions" />}
              MenuProps={MenuProps}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={getName('sharedInstitution', value)}
                      onDelete={() => {
                        deleteChip('sharedInstitution', value);
                      }}
                      style={{ zIndex: 10000 }}
                    />
                  ))}
                </Box>
              )}
            >
              {institutions.map((institution) => (
                <MenuItem key={institution._id} value={institution._id}>
                  {institution.institutionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
            name="duration"
            label="Exam Duration in Minutes"
            value={get(examInfo, 'duration', '')}
            style={{ width: '100%' }}
            onChange={(e) => {
              handleChange('duration', e);
            }}
            required
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />

          <FormControl fullWidth required>
            <InputLabel id="sessiom-label-t-Teacher">Teacher</InputLabel>
            <Select
              id="session-label-Teacher"
              value={get(examInfo, 'teacher', '')}
              label="Teacher"
              onChange={(e) => {
                handleChange('teacher', e);
              }}
            >
              {get(adminData, 'institutionId.teachers', '#')
                .split('#')
                .map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth required>
            <InputLabel id="sessiom-label-s">Session</InputLabel>
            <Select
              id="session-label-ss"
              value={get(examInfo, 'session', '')}
              label="Session"
              onChange={(e) => {
                handleChange('session', e);
              }}
            >
              {sessions.map((option) => (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth required>
            <InputLabel id="subject-level">Subject</InputLabel>
            <Select
              labelId="subject-level"
              id="subject-level"
              multiple
              value={get(examInfo, 'subject', [])}
              onChange={(e) => {
                handleChange3('subject', e);
              }}
              input={<OutlinedInput id="select-multiple-chip-batch" label="subject" />}
              MenuProps={MenuProps}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={getName('subject', value)}
                      onDelete={() => {
                        deleteChip('subject', value);
                      }}
                      style={{ zIndex: 10000 }}
                    />
                  ))}
                </Box>
              )}
            >
              {subjects.map((subject) => (
                <MenuItem key={subject.name} value={subject.id}>
                  {subject.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
            <DateTimePicker
              label="Exam START Time"
              name="startTime"
              value={moment(get(examInfo, 'startTime')).format('yyyy-MM-DD hh:mm A')}
              onChange={(newValue) => {
                setexamInfo({ ...examInfo, startTime: new Date(newValue) });
              }}
              inputFormat="DD/MM/YYYY hh:mm A"
              renderInput={(params) => <TextField {...params} />}
              PopperProps={{
                style: { zIndex: 10001 },
              }}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
            <DateTimePicker
              label="Exam END Time"
              name="endTime"
              value={moment(get(examInfo, 'endTime')).format('yyyy-MM-DD hh:mm A')}
              onChange={(newValue) => {
                setexamInfo({ ...examInfo, endTime: new Date(newValue) });
              }}
              inputFormat="DD/MM/YYYY hh:mm A"
              renderInput={(params) => <TextField {...params} />}
              style={{ width: '100%' }}
              PopperProps={{
                style: { zIndex: 10001 },
              }}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
            <DateTimePicker
              label="ANSWER Publish Time"
              name="answerPublishTime"
              value={moment(get(examInfo, 'answerPublishTime')).format('yyyy-MM-DD hh:mm A')}
              onChange={(newValue) => {
                setexamInfo({ ...examInfo, answerPublishTime: new Date(newValue) });
              }}
              inputFormat="DD/MM/YYYY hh:mm A"
              renderInput={(params) => <TextField {...params} />}
              PopperProps={{
                style: { zIndex: 10001 },
              }}
            />
          </LocalizationProvider>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          onClick={(e) => {
            handleCreateOrEdit();
          }}
        >
          CREATE / EDIT EXAM
        </LoadingButton>
      </Stack>

      {alertContent !== '' ? (
        <Alert variant="filled" severity="error">
          {alertContent}
        </Alert>
      ) : (
        <div />
      )}
    </FormProvider>
  );
}
