import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// APIS
import { getAllBatches } from 'src/axiosLib/services/batch/admin';

// material
import {
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextareaAutosize,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
import { sessions } from 'src/util/helpers';
import Scrollbar from '../../components/Scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import moment from 'moment';
import { Sms } from '@mui/icons-material';
import { getAllSMS, sendBulkSMSToBatch } from 'src/axiosLib/services/sms/admin';
import config from 'src/util/config';
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD_BATCH = [
  { id: 'name', label: 'Batch Name', alignRight: false },
  { id: 'address', label: 'Branch Address', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'phone', label: 'Contact phone', alignRight: false },

  { id: 'sendSms', label: 'Send SMS', alignRight: false },
];

const TABLE_HEAD_SMS = [
  { id: 'phone', label: 'Number', alignRight: false },
  { id: 'message', label: 'Sent Message', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'createdAt', label: 'Sending Time', alignRight: false },

  { id: 'status', label: 'Status', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [pageSms, setPageSms] = useState(0);

  const [order, setOrder] = useState('asc');

  const [branchNameForSMSModal, setBranchNameForSMSModal] = useState('');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [smsBodyBatch, setSmsBodyBatch] = useState('');

  const [batchIdForSMS, setBatchIdForSMS] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [rowsPerPageSms, setRowsPerPageSms] = useState(5);

  const [batchList, setBatchList] = useState([]);

  const [batchCount, setBatchCount] = useState(0);

  const [smsList, setSmsList] = useState([]);

  const [smsCount, setSmsCount] = useState(0);

  const [batchModal, setBatchModal] = useState(false);

  const [characterCount, setCharacterCount] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSms = (event, newPage) => {
    setPageSms(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChangeRowsPerPageSms = (event) => {
    // setRowsPerPageSms(parseInt(event.target.value, 5));
    setRowsPerPageSms(event.target.value);
    setPageSms(0);
  };

  const smsPopup = (batchId, branchName) => {
    console.log(batchId);
    setBatchIdForSMS(batchId);
    setBranchNameForSMSModal(branchName);
    setBatchModal(true);
  };

  const batchWiseSMS = () => {
    const dt = {
      batchId: batchIdForSMS,
      message: smsBodyBatch,
    };

    console.log(dt);

    try {
      sendBulkSMSToBatch(dt, (error, data) => {
        if (!error) {
          alert('Successfully sent SMS');
        } else {
          alert(error);
        }
      });
    } catch (error) {
      console.log(error);
    }

    setBatchModal(false);
  };

  const getBatches = (rowsPerPage, page) => {
    try {
      getAllBatches(rowsPerPage, page, (error, data) => {
        if (!error) {
          // console.log(data);
          setBatchList(get(data, 'batches', []));
          setBatchCount(get(data, 'size', 0));
        } else {
          navigate('/login', { replace: true });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSms = (rowsPerPageSms, pageSms) => {
    try {
      getAllSMS(rowsPerPageSms, pageSms, (error, data) => {
        if (!error) {
          setSmsList(data);
          setSmsCount(2500);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleBatchDataChange = (fieldName, event) => {
    // console.log(event.target.value);
    if (fieldName === 'body') {
      setCharacterCount(event.target.value.length);
    }

    setSmsBodyBatch({ ...smsBodyBatch, [fieldName]: event.target.value });
  };

  const sortedBatch = applySortFilter(batchList, getComparator(order, orderBy), filterName);

  useEffect(() => {
    getBatches(rowsPerPage, page);
  }, [filterName, page, rowsPerPage]);

  useEffect(() => {
    getSms(rowsPerPageSms, pageSms);
  }, [rowsPerPageSms, pageSms]);

  return (
    <Page title="SMS Service">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h3" gutterBottom>
            Automated SMS Service
          </Typography>
        </Stack>

        {/* Batch */}
        {smsList.length > 0 || config.IS_PUBLIC == 'true' ? (
          <Card>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
              <Typography
                variant="h4"
                gutterBottom
                style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
              >
                SMS Sending History
              </Typography>
            </Stack>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD_SMS}
                    rowCount={smsList.length}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {smsList.map((row) => {
                      return (
                        <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                          <TableCell align="left"> {get(row, 'sentToNumber', '')}</TableCell>
                          <TableCell align="left"> {get(row, 'messageBody', '')} </TableCell>
                          <TableCell align="left">
                            {' '}
                            {moment(get(row, 'createdAt')).format('( yyyy-MM-DD ) - ( hh:mm:ss a )')}
                          </TableCell>
                          <TableCell align="left"> {get(row, 'sentResponse', '')}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={smsCount}
              rowsPerPage={rowsPerPageSms}
              page={pageSms}
              onPageChange={handleChangePageSms}
              onRowsPerPageChange={handleChangeRowsPerPageSms}
            />
          </Card>
        ) : (
          console.log('batchwise sms sending tool')
        )}

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>
        {/* Batch */}
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              Batch wise SMS Sending Tool
            </Typography>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_BATCH}
                  rowCount={batchList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {sortedBatch.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'batchName', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'branchId.branchName', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'branchId.mobile', '')}</TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              onClick={() => {
                                smsPopup(get(row, '_id', ''), get(row, 'batchName', ''));
                              }}
                              style={{ color: '#FFFFFF', backgroundColor: '#880ED4' }}
                            >
                              <Sms style={{ color: '#FFFFFF', marginRight: 5 }} /> SEND SMS TO ALL STUDENTS
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={batchCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>
      </Container>

      {/* BATCH MODAL*/}
      <div>
        <Dialog open={batchModal} onClose={() => setBatchModal(false)}>
          <DialogTitle>Send SMS to: {branchNameForSMSModal}</DialogTitle>
          <DialogContent>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {' '}
            </Stack>
            <FormControl fullWidth required>
              <InputLabel id="sessiom-label">Session</InputLabel>
              <Select
                id="session-label"
                value={get(smsBodyBatch, 'session', '')}
                label="Session"
                onChange={(e) => {
                  handleBatchDataChange('session', e);
                }}
              >
                {sessions.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextareaAutosize
              name="message"
              label="Batch Name"
              style={{
                width: '100%',
                marginTop: 20,
                fontSize: '20px',
                fontFamily: 'Public Sans,sans-serif',
              }}
              value={get(smsBodyBatch, 'body', '')}
              onChange={(e) => {
                handleBatchDataChange('body', e);
              }}
              minRows="5"
              required
            />
            <p>{characterCount}</p>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                batchWiseSMS();
              }}
              style={{ color: 'white', backgroundColor: 'blue' }}
            >
              SEND SMS
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Page>
  );
}
