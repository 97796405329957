// @mui
import { useTheme } from '@mui/material/styles';
import { Button, Card, Container, Grid, Typography } from '@mui/material';

//API
// components
import Page from '../../components/Page';
// sections
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changeAdminInstitution, getAllInstitutions } from 'src/axiosLib/services/institution/admin';

// ----------------------------------------------------------------------

export default function SwitchInstituteApp() {
  const theme = useTheme();
  const navigate = useNavigate();
  const colourArray = ['info', 'error', 'warning'];

  const [institutions, setInstitutions] = useState([]);

  const totalUsers = () => {
    try {
      getAllInstitutions((error, data) => {
        if (!error) {
          setInstitutions(data);
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    totalUsers();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" gutterBottom>
          Institution List
        </Typography>

        <Grid container spacing={3}>
          {institutions.map((institution, idx) => (
            <Grid item xs={12} sm={6} md={3}>
              <Card
                sx={{
                  py: 5,
                  boxShadow: 0,
                  textAlign: 'center',
                  color: (theme) => theme.palette[colourArray[idx % colourArray.length]].darker,
                  bgcolor: (theme) => theme.palette[colourArray[idx % colourArray.length]].lighter,
                }}
                onClick={(e) => {
                  console.log('clicked', institution._id);
                  changeAdminInstitution({ _id: institution._id }, (error, data) => {
                    if (!error) {
                      alert('Successfully Switched Institution.');
                      window.location.reload();
                    } else {
                      alert('You are not eligible for this action.');
                    }
                  });
                }}
              >
                <Typography variant="h5" sx={{ opacity: 1 }}>
                  {'Name: '}
                  {institution.institutionName}
                </Typography>
                <Typography variant="subtitle1" sx={{ opacity: 0.72 }}>
                  {<strong>Address: </strong>}
                  {institution.address.replaceAll('$$$', ' ')}
                </Typography>
                <Typography variant="subtitle1" sx={{ opacity: 0.72 }}>
                  {<strong>Phone: </strong>}
                  {institution.mobile}
                </Typography>
                <Button variant="contained" color="primary">
                  SWITCH
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}
