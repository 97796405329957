import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

// APIS
// material
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import moment from 'moment';
import { Downloading } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AppWidget from 'src/sections/@dashboard/app/AppWidget';
import {
  confirmPaymentCollection,
  getAllPayments,
  getAllPaymentsExcel,
  getOverAllPaymentStatus,
  getOverAllPaymentStatusExcel,
} from 'src/axiosLib/services/payment/admin';
import dayjs from 'dayjs';
import { adminLevel } from '../../axiosLib/services/institution/admin';
import config from '../../util/config';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

// ----------------------------------------------------------------------

const TABLE_HEAD_PAYMENTS = [
  { id: 'amount', label: 'Payment Amount', alignRight: false },
  { id: 'sts', label: 'Status', alignRight: false },
  { id: 'cF', label: 'Course Fee', alignRight: false },
  { id: 'reff', label: 'Reference', alignRight: false },
  { id: 'Payment Method', label: 'Payment Method', alignRight: false },
  { id: 'username', label: 'User name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'roll', label: 'Roll', alignRight: false },
  { id: 'money-collected', label: 'Money Collected By', alignRight: false },
  { id: 'course-id', label: 'Course', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'edited', label: 'Edited?', alignRight: false },
];

const TABLE_HEAD = [
  { id: 'roll', label: 'Roll', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'guardianSMS', label: 'Guardian SMS', alignRight: false },
  { id: 'courseFee', label: 'Course Fee', alignRight: false },
  { id: 'payment collected', label: 'Payment Collected', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'profile', label: 'View Profile', alignRight: false },
];
// ----------------------------------------------------------------------

export default function Payment() {
  const navigate = useNavigate();

  const [pageUser, setPageUser] = useState(0);

  const [order, setOrder] = useState('asc');

  const [paymentStatus, setPaymentStatus] = useState('paid');

  const [paymentRowsPerPage, setPaymentRowsPerPage] = useState(10);

  const [rowsPerPageUser, setRowsPerPageUser] = useState(10);

  const [userList, setUserList] = useState([]);

  const [userCount, setUserCount] = useState(0);

  const [batchList, setBatchList] = useState([]);

  const [branchList, setBranchList] = useState([]);

  const [batchField, setBatchField] = useState('');

  const [paymentReqBody, setPaymentReqBody] = useState({
    fromDate: moment().format('yyyy-MM-DD'),
    toDate: moment().format('yyyy-MM-DD'),
  });

  const [adminData, setAdminData] = useState({});

  const [paymentCollectionModal, setPaymentCollectionModal] = useState(false);

  const [paymentCollectionData, setPaymentCollectionData] = useState({});

  const [canEditPayment, setCanEditPayment] = useState(false);

  const [paymentList, setPaymentList] = useState([]);

  const [paymentCount, setPaymentCount] = useState(0);

  const [paymentSummary, setPaymentSummary] = useState({});

  const [paymentPage, setPaymentPage] = useState(0);

  const [sessionField, setSessionField] = useState('');

  const isUserNotFound = userList.length === 0;

  const handleChangePagePayment = (event, newPage) => {
    setPaymentPage(newPage);
  };

  const handleChangeRowsPerPagePayment = (event) => {
    setPaymentRowsPerPage(event.target.value);
    setPaymentPage(0);
  };

  const handleChangePageUser = (event, newPage) => {
    setPageUser(newPage);
  };

  const handleChangeRowsPerPageUser = (event) => {
    setRowsPerPageUser(event.target.value);
    setPageUser(0);
  };

  const handleBatchFilter = (e) => {
    setBatchField(e.target.value);
  };

  const handleSessionFilter = (e) => {
    setSessionField(e.target.value);
  };

  const handleStatusFilter = (e) => {
    setPaymentStatus(e.target.value);
  };

  const getAllPaymentsOfStudents = () => {
    const tempObj = {
      fromDate: moment(paymentReqBody.fromDate).format('yyyy-MM-DD'),
      toDate: moment(paymentReqBody.toDate).format('yyyy-MM-DD'),
      limit: paymentRowsPerPage,
      page: paymentPage,
    };

    getAllPayments(tempObj, (error, data) => {
      // console.log(data)
      if (!error) {
        console.log(data.payments);
        setPaymentList(get(data, 'payments', []));
        setPaymentCount(get(data, 'extra.totalTransactions', 0));
        setPaymentSummary(get(data, 'extra', {}));
      } else {
        navigate('/login', { replace: true });
      }
    });
  };

  const getUsers = () => {
    try {
      const temp = {
        limit: rowsPerPageUser,
        page: pageUser,
        batchId: batchField,
        session: sessionField,
        status: paymentStatus,
      };

      getOverAllPaymentStatus(temp, (error, data) => {
        if (!error) {
          setUserList(get(data, 'paidUsers', []));
          setUserCount(get(data, 'totalEntry[0].totalTransactions', 0));

          console.log(data);
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const downloadExcel = () => {
    try {
      const temp = {
        batchId: batchField,
        session: sessionField,
        status: paymentStatus,
      };

      getOverAllPaymentStatusExcel(temp, (error, data) => {
        if (!error) {
          var XLSX = require('xlsx');
          const worksheet = XLSX.utils.json_to_sheet(data.paidUsers);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          XLSX.writeFile(workbook, 'Payment_History' + '.xlsx');

          console.log(data);
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const confirmPaymentCollectionFromAdmin = () => {
    confirmPaymentCollection(paymentCollectionData, (error, data) => {
      if (!error) {
        // console.log(data);

        let paymentId = get(paymentCollectionData, '_id', '');
        let modifiedPaymentLists = [];

        for (let i = 0; i < paymentList.length; i++) {
          modifiedPaymentLists.push(paymentList[i]);
          // console.log(paymentList);
          if (paymentId.toString() === paymentList[i]._id.toString()) {
            modifiedPaymentLists.at(-1).paymentCollected = !modifiedPaymentLists.at(-1).paymentCollected;
          }
        }

        alert('Successful');

        setPaymentList(modifiedPaymentLists);

        setPaymentCollectionModal(false);
      } else {
        alert('Something went wrong. Please try again later.');
      }
    });
  };

  const handlePaymentCollection = (row) => {
    if (!canEditPayment) {
      return;
    }

    setPaymentCollectionData(row);

    setPaymentCollectionModal(true);
  };

  const downloadExcelOfAllPayments = () => {
    const tempObj = {
      fromDate: moment(paymentReqBody.fromDate).format('yyyy-MM-DD'),
      toDate: moment(paymentReqBody.toDate).format('yyyy-MM-DD'),
    };

    getAllPaymentsExcel(tempObj, (error, data) => {
      if (!error) {
        var XLSX = require('xlsx');
        const worksheet = XLSX.utils.json_to_sheet(data.payments);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'Payment_Details' + '.xlsx');
      } else {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    // setPaymentReqBody({'fromDate' : '2023-03-04' , 'toDate' : '2023-04-04'})
    getAllPaymentsOfStudents();
  }, [paymentPage, paymentRowsPerPage]);

  useEffect(() => {
    adminLevel((error, data) => {
      if (!error) {
        setAdminData(get(data, 'admin'));

        setCanEditPayment(get(data, 'admin.roleSet', []).includes('EDIT_PAYMENT'));
      } else {
        console.log(error);
      }
    });
  }, []);

  // useEffect(() => {
  //   getUsers();
  // }, [pageUser, rowsPerPageUser, batchField, sessionField, paymentStatus]);

  return (
    <Page title="Revenue Track">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidget
              title="Payment Collected [Active Only]"
              total={get(paymentSummary, 'totalMoneyReceived', 0)}
              icon={'subway:sms-3'}
            />
          </Grid>

          {/*<Grid item xs={12} sm={6} md={3}>*/}
          {/*  <AppWidget*/}
          {/*    title="Tentative Revenue in BDT"*/}
          {/*    total={get(paymentSummary, 'totalCourseFee', 0)}*/}
          {/*    color="info"*/}
          {/*    icon={'fa6-solid:users-rays'}*/}
          {/*  />*/}
          {/*</Grid>*/}

          {/*<Grid item xs={12} sm={6} md={3}>*/}
          {/*  <AppWidget*/}
          {/*    title="Total Paid Students"*/}
          {/*    total={get(paymentSummary, 'totalUsers', 0)}*/}
          {/*    color="warning"*/}
          {/*    icon={'mdi:register'}*/}
          {/*  />*/}
          {/*</Grid>*/}

          <Grid item xs={12} sm={6} md={3}>
            <AppWidget
              title="Total Transactions"
              total={get(paymentSummary, 'totalTransactions', 0)}
              color="error"
              icon={'mdi:server-security'}
            />
          </Grid>
        </Grid>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          {' '}
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            All Payment Tracking Tool
          </Typography>
          {/* <Button variant="contained" component={RouterLink} to="/dashboard/register_student"
          startIcon={<Iconify icon="eva:plus-fill"/>}>
            Add New Student
          </Button> */}
        </Stack>

        {/* Batch */}
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            {' '}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-around" spacing={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Transaction From Date"
                name="fromDate"
                value={moment(get(paymentReqBody, 'fromDate')).format('yyyy-MM-DD')}
                onChange={(newValue) => {
                  setPaymentReqBody({ ...paymentReqBody, fromDate: new Date(newValue) });
                  // setexamInfo({...examInfo, startTime: new Date(newValue)});
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} />}
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
                minDate={dayjs().subtract(canEditPayment ? 1000 : 45, 'day')}
                maxDate={dayjs().add(1, 'days')}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Transaction to Date"
                name="toDate"
                value={moment(get(paymentReqBody, 'toDate')).format('yyyy-MM-DD')}
                onChange={(newValue) => {
                  setPaymentReqBody({ ...paymentReqBody, toDate: new Date(newValue) });
                }}
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} />}
                sx={{ width: 220 }}
                InputLabelProps={{
                  shrink: true,
                }}
                minDate={dayjs().subtract(canEditPayment ? 1000 : 45, 'day')}
                maxDate={dayjs().add(1, 'days')}
              />
            </LocalizationProvider>

            <Button
              variant="contained"
              onClick={(e) => {
                getAllPaymentsOfStudents();
              }}
              style={{ color: 'white', backgroundColor: 'blue', width: '20%', marginRight: 15 }}
            >
              {/* <Downloading style={{color: "white", marginRight: 5}}/> */}
              FILTER
            </Button>

            <Button
              variant="contained"
              onClick={(e) => downloadExcelOfAllPayments()}
              style={{ color: 'white', backgroundColor: 'blue', width: '30%', marginRight: 15 }}
            >
              <Downloading style={{ color: 'white', marginRight: 5 }} />
              Download Excel
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer  >
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD_PAYMENTS}
                  rowCount={paymentList.length}
                  // onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {paymentList.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'amount', 0)} BDT</TableCell>
                        <TableCell align="left">
                          {' '}
                          {get(row, 'paymentCollected') === false ? (
                            canEditPayment ? (
                              <Button
                                variant="contained"
                                style={{ backgroundColor: 'brown' }}
                                onClick={() => handlePaymentCollection(row)}
                              >
                                Collect Payment
                              </Button>
                            ) : (
                              <Button variant="contained" style={{ backgroundColor: 'brown' }}>
                                Payment Collection Pending
                              </Button>
                            )
                          ) : (
                            <Button
                              variant="contained"
                              style={{ backgroundColor: 'green' }}
                              onClick={() => handlePaymentCollection(row)}
                            >
                              Already Collected
                            </Button>
                          )}
                        </TableCell>
                        <TableCell align="left"> {get(row, 'courseFee', 0)} BDT</TableCell>
                        <TableCell align="left"> {get(row, 'reference', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'paymentMethod', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'userId.username', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'userId.phone', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'userId.roll', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'verifiedBy.nickName', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'courseId.title', '')}</TableCell>
                        <TableCell align="left"> {moment(get(row, 'createdAt')).format('yyyy-MM-DD')}</TableCell>
                        <TableCell align="left">
                          {get(row, 'isActive') === false ? (
                            <Button
                              variant="contained"
                              style={{
                                background: 'red',
                                color: '#fff',
                              }}
                            >
                              INACTIVE
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              style={{
                                background: 'green',
                                color: '#fff',
                              }}
                            >
                              ACTIVE
                            </Button>
                          )}

                          {
                            <div>
                              <Button
                                variant="contained"
                                style={{ marginTop: '10px' }}
                                onClick={() => {
                                  let link =
                                    config.WEB_LINK + 'dashboard/register_student/?std=' + get(row, 'userId._id', '');

                                  window.open(link, '_blank');
                                }}
                              >
                                STUDENT
                              </Button>
                              <Button
                                variant="contained"
                                style={{ marginTop: '10px', backgroundColor: 'brown' }}
                                onClick={() => {
                                  let link = config.WEB_LINK + 'invoice/?invoiceId=' + get(row, '_id', '');

                                  window.open(link, '_blank');
                                }}
                              >
                                PRINT
                              </Button>
                            </div>
                          }
                        </TableCell>

                        {get(row, 'archive', []).length > 0 ? (
                          <TableCell align="left">
                            {' '}
                            {
                              <Button
                                variant="contained"
                                style={{
                                  color: '#FFFFFF',
                                  backgroundColor: '#880ED4',
                                }}
                              >
                                {get(row, 'archive', []).length}
                              </Button>
                            }
                          </TableCell>
                        ) : (
                          console.log('N/A')
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100, 150, 250, 500]}
            component="div"
            count={paymentCount}
            rowsPerPage={paymentRowsPerPage}
            page={paymentPage}
            onPageChange={handleChangePagePayment}
            onRowsPerPageChange={handleChangeRowsPerPagePayment}
          />
        </Card>

        {/*<Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>*/}
        {/*  {' '}*/}
        {/*</Stack>*/}

        {/*/!* USER *!/*/}
        {/*<Card>*/}
        {/*  <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>*/}
        {/*    {' '}*/}
        {/*  </Stack>*/}
        {/*  <Stack direction="row" alignItems="center" justifyContent="space-around" spacing={5}>*/}
        {/*    <FormControl fullWidth required style={{ width: '30%', marginLeft: 15 }}>*/}
        {/*      <InputLabel id="sessiom-label">Session</InputLabel>*/}
        {/*      <Select*/}
        {/*        id="session-label"*/}
        {/*        defaultValue={''}*/}
        {/*        label="Session"*/}
        {/*        onChange={(e) => {*/}
        {/*          handleSessionFilter(e);*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        {sessions.map((option) => (*/}
        {/*          <MenuItem value={option} key={option}>*/}
        {/*            {option}*/}
        {/*          </MenuItem>*/}
        {/*        ))}*/}
        {/*      </Select>*/}
        {/*    </FormControl>*/}

        {/*    /!* <FormControl fullWidth required style={{width : "50%"}}>*/}
        {/*              <InputLabel id="sessiom-label">Branch Name</InputLabel>*/}
        {/*              <Select*/}
        {/*                id="branch-label"*/}
        {/*                // value={batchField}*/}
        {/*                defaultValue={""}*/}
        {/*                label="Batch Name"*/}
        {/*                onChange={(e) => {handleBranchFilter(e)}}*/}
        {/*                >*/}
        {/*                  */}
        {/*                {branchList.map((batch) => (*/}
        {/*                  <MenuItem*/}
        {/*                    key={batch.branchName}*/}
        {/*                    value={batch._id}*/}
        {/*                  >*/}
        {/*                    {batch.branchName}*/}
        {/*                  </MenuItem>*/}
        {/*                ))}*/}
        {/*              </Select>*/}
        {/*          </FormControl> *!/*/}

        {/*    <FormControl fullWidth required style={{ width: '50%' }}>*/}
        {/*      <InputLabel id="sessiom-label">Batch Name</InputLabel>*/}
        {/*      <Select*/}
        {/*        id="session-label"*/}
        {/*        // value={get(grantData, 'batchId', '')}*/}
        {/*        defaultValue={''}*/}
        {/*        label="Batch Name"*/}
        {/*        onChange={(e) => handleBatchFilter(e)}*/}
        {/*      >*/}
        {/*        {batchList.map((batch) => (*/}
        {/*          <MenuItem key={batch.batchName} value={batch._id}>*/}
        {/*            {batch.batchName}*/}
        {/*          </MenuItem>*/}
        {/*        ))}*/}
        {/*      </Select>*/}
        {/*    </FormControl>*/}

        {/*    <FormControl fullWidth required style={{ width: '30%' }}>*/}
        {/*      <InputLabel id="sessiom-label">Payment Status</InputLabel>*/}
        {/*      <Select*/}
        {/*        id="payment-status-label"*/}
        {/*        value={paymentStatus}*/}
        {/*        label="Payment Status"*/}
        {/*        onChange={(e) => {*/}
        {/*          handleStatusFilter(e);*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        {paymentStatusList.map((option) => (*/}
        {/*          <MenuItem value={option} key={option}>*/}
        {/*            {option}*/}
        {/*          </MenuItem>*/}
        {/*        ))}*/}
        {/*      </Select>*/}
        {/*    </FormControl>*/}

        {/*    <Button*/}
        {/*      variant="contained"*/}
        {/*      onClick={(e) => {*/}
        {/*        downloadExcel();*/}
        {/*      }}*/}
        {/*      style={{ color: 'white', backgroundColor: 'blue', width: '20%', marginRight: 15 }}*/}
        {/*    >*/}
        {/*      <Downloading style={{ color: 'white', marginRight: 5 }} />*/}
        {/*      DOWNLOAD*/}
        {/*    </Button>*/}
        {/*  </Stack>*/}

        {/*  <Scrollbar>*/}
        {/*    <TableContainer sx={{ minWidth: 800 }}>*/}
        {/*      <Table>*/}
        {/*        <UserListHead order={order} headLabel={TABLE_HEAD} rowCount={userList.length} />*/}
        {/*        <TableBody>*/}
        {/*          /!* {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => { *!/*/}
        {/*          /!* // const { id, name, role, status, company, avatarUrl, isVerified } = row;*/}
        {/*            // const isItemSelected = selected.indexOf(name) !== -1; *!/*/}
        {/*          {userList.map((row, i) => {*/}
        {/*            return (*/}
        {/*              <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>*/}
        {/*                <TableCell align="left"> {get(row, 'roll', '')}</TableCell>*/}

        {/*                <TableCell align="left"> {get(row, 'username', '')}</TableCell>*/}
        {/*                <TableCell align="left"> {get(row, 'phone', '')}</TableCell>*/}
        {/*                <TableCell align="left"> {get(row, 'guardianSMS', '')}</TableCell>*/}
        {/*                <TableCell align="left"> {get(row, 'courseFee', 0)}</TableCell>*/}
        {/*                <TableCell align="left"> {get(row, 'paid', 0)}</TableCell>*/}
        {/*                <TableCell align="left">*/}
        {/*                  {get(row, 'courseFee', 0) - get(row, 'paid', 0) > 0 ? (*/}
        {/*                    <Button*/}
        {/*                      variant="contained"*/}
        {/*                      style={{*/}
        {/*                        background: 'red',*/}
        {/*                        color: '#fff',*/}
        {/*                      }}*/}
        {/*                    >*/}
        {/*                      DUE {get(row, 'courseFee', 0) - get(row, 'paid', 0)} BDT*/}
        {/*                    </Button>*/}
        {/*                  ) : (*/}
        {/*                    <Button*/}
        {/*                      variant="contained"*/}
        {/*                      style={{*/}
        {/*                        background: 'green',*/}
        {/*                        color: '#fff',*/}
        {/*                      }}*/}
        {/*                    >*/}
        {/*                      PAID*/}
        {/*                    </Button>*/}
        {/*                  )}*/}
        {/*                </TableCell>*/}

        {/*                <TableCell align="left">*/}
        {/*                  {' '}*/}
        {/*                  {*/}
        {/*                    <Button*/}
        {/*                      variant="contained"*/}
        {/*                      component={RouterLink}*/}
        {/*                      to={'/dashboard/register_student/?std=' + get(row, '_id')}*/}
        {/*                    >*/}
        {/*                      VIEW*/}
        {/*                    </Button>*/}
        {/*                  }*/}
        {/*                </TableCell>*/}
        {/*              </TableRow>*/}
        {/*            );*/}
        {/*          })}*/}
        {/*        </TableBody>*/}

        {/*        {isUserNotFound && (*/}
        {/*          <TableBody>*/}
        {/*            <TableRow>*/}
        {/*              <TableCell align="center" colSpan={6} sx={{ py: 3 }}>*/}
        {/*                <SearchNotFound searchQuery={'This Session'} />*/}
        {/*              </TableCell>*/}
        {/*            </TableRow>*/}
        {/*          </TableBody>*/}
        {/*        )}*/}
        {/*      </Table>*/}
        {/*    </TableContainer>*/}
        {/*  </Scrollbar>*/}

        {/*  <TablePagination*/}
        {/*    rowsPerPageOptions={[10, 25, 100]}*/}
        {/*    component="div"*/}
        {/*    count={userCount}*/}
        {/*    rowsPerPage={rowsPerPageUser}*/}
        {/*    page={pageUser}*/}
        {/*    onPageChange={handleChangePageUser}*/}
        {/*    onRowsPerPageChange={handleChangeRowsPerPageUser}*/}
        {/*  />*/}
        {/*</Card>*/}

        {/* SUBJECT MODAL*/}
        <div>
          <Dialog open={paymentCollectionModal} onClose={() => setPaymentCollectionModal(false)}>
            <DialogTitle>Collected Payment Details</DialogTitle>

            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Card sx={{ width: '90%', marginBottom: '30px', border: '2px solid #800000' }}>
                <CardContent
                  style={{ backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px' }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                    <Typography variant="h6" style={{ textDecoration: 'underline' }}>{`Payment Amount: ${get(
                      paymentCollectionData,
                      'amount',
                      ''
                    )}`}</Typography>
                    <Typography variant="h6" style={{ textDecoration: 'underline' }}>{`Money Collected By: ${get(
                      paymentCollectionData,
                      'verifiedBy.nickName',
                      ''
                    )}`}</Typography>
                    <Typography variant="h6">{`Student Name: ${get(
                      paymentCollectionData,
                      'userId.username',
                      ''
                    )}`}</Typography>
                    <Typography variant="h6">{`Student Phone: ${get(
                      paymentCollectionData,
                      'userId.phone',
                      ''
                    )}`}</Typography>
                    <Typography variant="h6">{`Student Roll: ${get(
                      paymentCollectionData,
                      'userId.roll',
                      ''
                    )}`}</Typography>
                    <Typography variant="h6">{`Payment Method: ${get(
                      paymentCollectionData,
                      'paymentMethod',
                      ''
                    )}`}</Typography>
                    <Typography variant="h6">{`Course Fee For This Student: ${get(
                      paymentCollectionData,
                      'courseFee',
                      ''
                    )}`}</Typography>
                    <Typography variant="h6">{`Course Name: ${get(
                      paymentCollectionData,
                      'courseId.title',
                      ''
                    )}`}</Typography>
                  </div>
                </CardContent>
              </Card>
            </div>

            <DialogActions>
              <Button
                variant="contained"
                style={{ color: 'white', backgroundColor: 'blue' }}
                onClick={confirmPaymentCollectionFromAdmin}
              >
                {get(paymentCollectionData, 'paymentCollected') ? 'Remove' : 'Confirm'}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </Page>
  );
}
