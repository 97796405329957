import { getReqAuth, postReqAuth } from '../../api';

export const getAllCoursesAdmin = (cb) => getReqAuth('/webapi/institute/course/get/all', {}, '', cb);

export const getFilteredCoursesAdmin = (data, cb) =>
  postReqAuth('/webapi/institute/course/get/all-filtered', data, '', cb);

export const getAllCoursesOfOneStudent = (userId, cb) =>
  getReqAuth('/webapi/institute/course/get/all-course-of-student/', {}, userId, cb);

export const getSingleCoursesAdmin = (courseId, cb) =>
  getReqAuth('/webapi/institute/course/get/single/', {}, courseId, cb);

export const createCourse = (data, cb) => postReqAuth('/webapi/institute/course/create', data, '', cb);

export const createNewCourseEnrollmentAdmin = (data, cb) =>
  postReqAuth('/webapi/institute/course/create/enrollment/', data, '', cb);

export const getEnrolledUsersInCourse = (
  courseId,
  text,
  limit,
  page,
  branchId,
  batchId,
  session,
  enrollmentType,
  cb
) => {
  postReqAuth(
    '/webapi/institute/course/get/enrollments',
    {
      courseId,
      text,
      limit,
      page,
      branchId,
      batchId,
      session,
      enrollmentType,
    },
    '',
    cb
  );
};
