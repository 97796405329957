import { getReqAuth, postReqAuth } from '../../api';

// 1. Create New Exam
export const setExam = (data, cb) => postReqAuth("/webapi/institute/exam/create", data, "", cb);

// 1. Edit Exam
export const editExam = (data, cb) => postReqAuth("/webapi/institute/exam/update", data, "", cb);

// 2. Get All Exam
export const getAllExams = (limit, page, isMobileScanEnabled , cb) =>
	postReqAuth("/webapi/institute/exam/get/all", {limit, page, isMobileScanEnabled}, "", cb);

export const getAllExamsForCourse = (limit, page, courseId , cb) =>
	postReqAuth("/webapi/institute/exam/get/all/courseExams", {limit, page, courseId}, "", cb);

// 2. Get Merit List
export const getMeritList = (id, limit, page, cb) =>
	postReqAuth("/webapi/institute/exam/leaderboard/", {limit, page}, id, cb);

export const exportMeritList = (data, id, cb) =>
	postReqAuth("/webapi/institute/exam/leaderboard/excel/", data, id, cb);

export const sendSMSMeritList = (id, data, cb) =>
	postReqAuth("/webapi/institute/exam/leaderboard/sms/", {data}, id, cb);

export const exportAbsenteeList = (data, id, cb) =>
	postReqAuth("/webapi/institute/exam/absent/excel/", data, id, cb);

export const sendSMSAbsenteeList = (id, cb) =>
	getReqAuth("/webapi/institute/exam/absent/sms/", {}, id, cb);

export const getAbsentRatio = (cb) => getReqAuth("/webapi/institute/exam/absent/ratio", {}, "", cb);

export const getExamAverage = (id, cb) => getReqAuth("/webapi/institute/exam/average/", {}, id, cb);

// 2. Get Merit List
export const getAbsentList = (id, limit, page, cb) =>
	postReqAuth("/webapi/institute/exam/absent/list/", {limit, page}, id, cb);

// 3. Get Exam Detail
export const getExamDetail = (id, cb) => getReqAuth("/webapi/institute/exam/get/detail/", {}, id, cb);

// 3. Get Exam Detail
export const deleteExam = (id, cb) => getReqAuth("/webapi/institute/exam/delete/", {}, id, cb);

// 3. Add MCQ
export const addMCQQustions = (id, data, cb) =>
	postReqAuth("/webapi/institute/exam/add/mcq/", data, id, cb);

export const deleteMCQQustions = (id, data, cb) =>
	postReqAuth("/webapi/institute/exam/delete/mcq/", data, id, cb);

// 3. Get Exam Detail
export const getExamPerformance = (limit, page, cb) =>
	postReqAuth("/webapi/institute/exam/performance", {limit, page}, "", cb);

export const verifyAdmin = (id, cb) => getReqAuth("/webapi/institute/exam/verify/", {}, id, cb);

// search absentee list
export const searchAbsenteeList = (id, data, cb) =>
	postReqAuth("/webapi/institute/exam/absent/search/", data, id, cb);

// search merit list
export const searchMeritList = (id, data, cb) =>
	postReqAuth("/webapi/institute/exam/leaderboard/search/", data, id, cb);

export const marksChange = ( data, cb) =>
	postReqAuth("/webapi/institute/exam/marks/update", {data}, "", cb);

// 2. Get Exam Detail ( User )
export const getExamSolutionAdmin = (data, cb) =>
	postReqAuth("/webapi/institute/exam/get/solution/", data, "", cb);

export const getPrintableExamAdmin = (data, cb) =>
	postReqAuth("/webapi/institute/exam/get/solution/printable", data, "", cb);
