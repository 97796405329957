import { getReqAuth, postReqAuth } from '../../api';

// 1. get all users
export const usersExport = (data, cb) =>
	postReqAuth("/webapi/institute/user/get/verified", data, "", cb);

export const getUsersFiltered = (text,limit, page, branchId, batchId,session, cb) =>{
	postReqAuth("/webapi/institute/user/get/filtered", {text, limit, page, branchId, batchId, session}, "", cb);
}

export const getUserRatioPie = (cb) =>{
	getReqAuth("/webapi/institute/user/allStudents/ratio", {}, "", cb);
}

export const searchPhone = (limit, page, phone, cb) =>
	postReqAuth("/webapi/institute/user/search/", {limit, page}, phone, cb);

export const getUserDetail = (id, cb) =>
	getReqAuth("/webapi/institute/user/get/detail/", {}, id, cb);

export const changeUserPass = (id, cb) =>
	getReqAuth("/webapi/institute/user/changepassword/", {}, id, cb);

export const getUserPerformance = (id, cb) =>
	getReqAuth("/webapi/institute/user/performance/", {}, id, cb);

export const getUserPerformanceExcel = (limit,page, id, cb) =>
	postReqAuth("/webapi/institute/user/excel/", {limit,page}, id, cb);

export const getUserPerformanceOfExams = (limit,page, id, cb) =>
	postReqAuth("/webapi/institute/user/examResult/", {limit,page}, id, cb);

export const getUserCount = (cb) => getReqAuth("/webapi/institute/user/get/count", {}, "", cb);

export const getBatchSummary = (cb) => getReqAuth("/webapi/institute/user/batch/summary", {}, "", cb);

export const getAllUnverifiedUsers = (limit, page, cb) =>
	postReqAuth("/webapi/institute/user/get/unverified", {limit, page}, "", cb);

export const getAllRemovedUsers = (limit, page, cb) =>
	postReqAuth("/webapi/institute/user/get/removed", {limit, page}, "", cb);

export const getAllInstituteChangeRequestedUsers = (limit, page, cb) =>
	postReqAuth("/webapi/institute/user/get/institutionChanged", {limit, page}, "", cb);

export const verifyUser = (id, cb) => getReqAuth("/webapi/institute/user/verify/", {}, id, cb);

export const blockUser = (id, cb) => getReqAuth("/webapi/institute/user/block/", {}, id, cb);

export const removeUser = (id, cb) => getReqAuth("/webapi/institute/user/remove/", {}, id, cb);

export const restoreUser = (id, cb) => getReqAuth("/webapi/institute/user/restore/", {}, id, cb);

export const deleteUser = (id, cb) => getReqAuth("/webapi/institute/user/delete/", {}, id, cb);

export const setValidTill = (id, validTill, cb) =>
	postReqAuth("/webapi/institute/user/validity/", {validTill}, id, cb);

export const enrollUser = (id, data, cb) => postReqAuth("/webapi/institute/user/enroll/", data, id, cb);

export const setPriviledge = (id, data, cb) =>
	postReqAuth("/webapi/institute/set/priviledge/", data, id, cb);

export const createStudent = (data, cb) => postReqAuth("/webapi/institute/user/create/", data, "", cb);

export const updateStudent = (id, data, cb) =>
	postReqAuth("/webapi/institute/user/edit/", data, id, cb);

export const uploadStudentPhoto = (data, cb) =>
	postReqAuth("/webapi/institute/user/upload-student-photo/", data, "", cb);
