// @mui
import { Checkbox, Container, Stack } from '@mui/material';
// hooks
// components
import Page from '../../components/Page';
// sections
import { useEffect, useState } from 'react';
import { RadioButtonCheckedTwoTone, RadioButtonUncheckedTwoTone } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

const commonStyles = {
  bgcolor: 'background.paper',
  borderColor: 'text.primary',
  m: 1,
  border: 3,
  width: '3rem',
  height: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  cursor: 'pointer', // Cursor changes to pointer on hover
  '&:hover': {
    backgroundColor: '#1565c0', // Darker background on hover
  },
};

export default function UserCheckFilledAnswersMobileOmr() {
  const navigate = useNavigate();

  const [checkedItems, setCheckedItems] = useState([]);

  const handleChange = (index) => (event) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = event.target.checked; // Update specific checkbox
    setCheckedItems(updatedCheckedItems);

    console.log(updatedCheckedItems);
  };

  const resultData = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const examCode = urlParams.get('examCode');

    const storedItems = sessionStorage.getItem('cItm:' + examCode);

    if (storedItems) {
      setCheckedItems(JSON.parse(storedItems));
    } else {
      navigate('/user/mobileOmrScan?examCode=' + examCode);
    }
  };

  const renderCheckboxRows = () => {
    const rows = [];

    for (let i = 0; i < checkedItems.length; i += 4) {
      const rowItems = checkedItems.slice(i, i + 4); // Get 4 items per row

      rows.push(
        <Box
          key={i}
          sx={{
            border: '4px solid #ccc', // Box border
            borderRadius: '8px', // Rounded corners
            padding: '8px', // Spacing inside the box
            marginBottom: '16px', // Space between rows
            backgroundColor: '#f9f9f9', // Light background color
          }}
        >
          <Stack key={i} direction="row" alignItems="center">
            {/* Serial Number */}
            <div style={{ marginRight: '1.5rem', fontWeight: 'bold' }}>{Math.floor(i / 4) + 1}.</div>

            {/* Row of Checkboxes */}
            {rowItems.map((checked, index) => (
              <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ width: '100%' }}>
                <Box
                  key={i + index}
                  sx={{
                    border: '1px solid #ccc',
                    width: '100%',
                    flexGrow: 1, // Allows equal space for all boxes
                    display: 'flex', // Enables content alignment
                    justifyContent: 'center', // Centers content horizontally
                    alignItems: 'center', // Centers content vertically
                  }}
                >
                  <span style={{ margin: 0 }}>{String.fromCharCode(((i + index) % 4) + 65)}</span> {/* Label */}
                  <Checkbox
                    key={index}
                    checked={checked}
                    onChange={handleChange(i + index)}
                    icon={<RadioButtonUncheckedTwoTone />} // Unchecked state
                    checkedIcon={<RadioButtonCheckedTwoTone />} // Checked state
                    sx={{ margin: 0 }}
                  />
                </Box>
              </Stack>
            ))}
          </Stack>
        </Box>
      );
    }
    return rows;
  };

  useEffect(() => {
    resultData();
  }, []);

  return (
    <Page title="Exam Result Check">
      <Container maxWidth="xl" sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
        {renderCheckboxRows()}
      </Container>
    </Page>
  );
}
