import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// APIS
import { createBatch, getAllBatches } from 'src/axiosLib/services/batch/admin';
import { createBranch, getAllBranches } from 'src/axiosLib/services/branch/admin';
import { createSubject, getAllSubjects } from 'src/axiosLib/services/subject/admin';
// material
import {
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import { UserListHead } from '../../sections/@dashboard/user';
import moment from 'moment';
import { Edit } from '@mui/icons-material';

// ----------------------------------------------------------------------

const TABLE_HEAD_BATCH = [
  { id: 'name', label: 'Branch Name', alignRight: false },
  { id: 'address', label: 'Branch Address', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'email', label: 'Contact Email', alignRight: false },
  // { id: 'email', label: 'Phone', alignRight: false },
  { id: 'updatedAt', label: 'Updated At', alignRight: false },
  // { id: 'registered_at', label: 'Registered at', alignRight: false },
  { id: 'profile', label: 'EDIT', alignRight: false },
];

const TABLE_HEAD_BRANCH = [
  { id: 'name', label: 'Batch Name', alignRight: false },
  { id: 'branch', label: 'Branch Name', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'updatedAt', label: 'Updated At', alignRight: false },
  // { id: 'registered_at', label: 'Registered at', alignRight: false },
  { id: 'profile', label: 'EDIT', alignRight: false },
];

const TABLE_HEAD_SUBJECT = [
  { id: 'name', label: 'Subject Name', alignRight: false },
  { id: 'code', label: 'Subject Code', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  // { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'updatedAt', label: 'Last Updated At', alignRight: false },
  // { id: 'registered_at', label: 'Registered at', alignRight: false },
  { id: 'profile', label: 'EDIT', alignRight: false },
];
// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [branchModal, setBranchModal] = useState(false);
  const [batchModal, setBatchModal] = useState(false);
  const [subjectModal, setSubjectModal] = useState(false);

  const [batchData, setBatchData] = useState();
  const [branchData, setBranchData] = useState();
  const [subjectData, setSubjectData] = useState();

  const navigate = useNavigate();

  const [page, setPage] = useState(0);

  const [pageB, setPageB] = useState(0);

  const [pageSub, setPageSub] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [rowsPerPageSub, setRowsPerPageSub] = useState(5);

  const [rowsPerPageB, setRowsPerPageB] = useState(5);

  const [batchList, setBatchList] = useState([]);

  const [batchCount, setBatchCount] = useState(0);

  const [branchList, setBranchList] = useState([]);

  const [branchCount, setBranchCount] = useState(0);

  const [subjectList, setSubjectList] = useState([]);

  const [subjectCount, setSubjectCount] = useState(0);

  const handleBatchDataChange = (fieldName, event) => {
    // console.log(event.target.value);
    setBatchData({ ...batchData, [fieldName]: event.target.value });
  };

  const handleBranchDataChange = (fieldName, event) => {
    // console.log(event.target.value);
    setBranchData({ ...branchData, [fieldName]: event.target.value });
  };

  const handleSubjectDataChange = (fieldName, event) => {
    // console.log(event.target.value);
    setSubjectData({ ...subjectData, [fieldName]: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageBranch = (event, newPage) => {
    setPageB(newPage);
  };

  const handleChangePageSubject = (event, newPage) => {
    setPageSub(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    // setPage(0);
  };

  const handleChangeRowsPerPageBranch = (event) => {
    setRowsPerPageB(event.target.value);
    // setPage(0);
  };

  const handleChangeRowsPerPageSubject = (event) => {
    setRowsPerPageSub(event.target.value);
    // setPage(0);
  };

  const handleFilterByName = (event) => {
    const filterValue = event.target.value;
    setPage(0);
    setRowsPerPage(10);
    setFilterName(filterValue);
  };

  const getBatches = (rowsPerPage, page) => {
    try {
      getAllBatches(rowsPerPage, page, (error, data) => {
        if (!error) {
          // console.log(data);
          setBatchList(get(data, 'batches', []));
          setBatchCount(get(data, 'size', 0));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getBranches = (rowsPerPageB, pageB) => {
    try {
      getAllBranches(rowsPerPageB, pageB, (error, data) => {
        if (!error) {
          // console.log(data);
          setBranchList(get(data, 'branches', []));
          setBranchCount(get(data, 'size', 0));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSubjects = (rowsPerPageSub, pageSub) => {
    try {
      getAllSubjects(rowsPerPageSub, pageSub, (error, data) => {
        if (!error) {
          // console.log(data);
          setSubjectList(get(data, 'subjects', []));
          setSubjectCount(get(data, 'size', 0));
        } else {
          navigate('/login', { replace: true });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleBatchEdit = (row) => {
    const temp = {
      _id: get(row, '_id', ''),
      batchName: get(row, 'batchName', ''),
    };

    setBatchData(temp);

    setBatchModal(true);
  };

  const handleBranchEdit = (row) => {
    const temp = {
      _id: get(row, '_id', ''),
      address: get(row, 'address', ''),
      branchName: get(row, 'branchName', ''),
      email: get(row, 'email', ''),
      mobile: get(row, 'mobile', ''),
    };

    setBranchData(temp);

    setBranchModal(true);
  };

  const handleSubjectEdit = (row) => {
    const temp = {
      _id: get(row, '_id', ''),
      subjectCode: get(row, 'subjectCode', ''),
      name: get(row, 'name', ''),
    };

    setSubjectData(temp);

    setSubjectModal(true);
  };

  const insertBatch = () => {
    createBatch(batchData, (error, data) => {
      if (!error) {
        // console.log(data);

        // dashboard/institute
        // navigate('/dashboard/institute', { replace: true });
        // console.log(data);
        window.location.reload(false);
      } else {
        console.log(error);
      }
    });

    // setBatchModal(false)
  };

  const insertBranch = () => {
    createBranch(branchData, (error, data) => {
      if (!error) {
        // console.log(data);

        // navigate('/dashboard/institute', { replace: true });
        window.location.reload(false);
      } else {
        console.log(error);
      }
    });

    // setBranchModal(false)
  };

  const insetSubject = () => {
    createSubject(subjectData, (error, data) => {
      if (!error) {
        // console.log(data);

        window.location.reload(false);
      } else {
        alert('Something went wrong');
      }
    });

    // setSubjectModal(false)
  };

  useEffect(() => {
    getBatches(rowsPerPage, page);
  }, [page, rowsPerPage]);

  useEffect(() => {
    getBranches(rowsPerPageB, pageB);
  }, [rowsPerPageB, pageB]);

  useEffect(() => {
    getSubjects(rowsPerPageSub, pageSub);
  }, [rowsPerPageSub, pageSub]);

  return (
    <Page title="Institute Details">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h3" gutterBottom>
            Institute Details
          </Typography>
        </Stack>

        {/* Batch */}
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              Batch Information
            </Typography>
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleBatchEdit({})}
            >
              Add New Batch
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_BRANCH}
                  rowCount={batchList.length}
                />
                <TableBody>
                  {batchList.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'batchName', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'branchId.branchName', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'branchId.mobile', '')}</TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <TextField
                              id="date"
                              label="Batch Last Modified"
                              type="date"
                              defaultValue={moment(row.updatedAt).format('yyyy-MM-DD')}
                              // onChange={() => { console.log('changing date') }}
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          }
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              onClick={() => handleBatchEdit(row)}
                              style={{ color: '#880ED4', backgroundColor: 'white' }}
                            >
                              <Edit style={{ color: '#880ED4', marginRight: 5 }} /> EDIT BATCH
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={batchCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>

        {/* Branch */}

        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              Branch Information
            </Typography>
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleBranchEdit({})}
            >
              Add New Branch
            </Button>
            {/* <NewBranch open = {open} onClick={() => setOpen(true)}/> */}
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_BATCH}
                  rowCount={branchList.length}
                />
                <TableBody>
                  {branchList.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'branchName', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'address', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'email', '')}</TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <TextField
                              id="date"
                              label="Batch Last Modified"
                              type="date"
                              defaultValue={moment(get(row, 'updatedAt', '')).format('yyyy-MM-DD')}
                              onChange={() => {
                                console.log('changing date');
                              }}
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          }
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              onClick={() => handleBranchEdit(row)}
                              style={{ color: '#880ED4', backgroundColor: 'white' }}
                            >
                              <Edit style={{ color: '#880ED4', marginRight: 5 }} /> EDIT
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={branchCount}
            rowsPerPage={rowsPerPageB}
            page={pageB}
            onPageChange={handleChangePageBranch}
            onRowsPerPageChange={handleChangeRowsPerPageBranch}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>

        {/* Subject */}
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
            <Typography
              variant="h4"
              gutterBottom
              style={{ marginLeft: 15, color: '#FF0000', backgroundColor: 'white' }}
            >
              Subjects List
            </Typography>
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => handleSubjectEdit({})}
            >
              Add New Subject
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD_SUBJECT}
                  rowCount={subjectList.length}
                />
                <TableBody>
                  {subjectList.map((row) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'name', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'subjectCode', '')}</TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <TextField
                              id="date"
                              label="Batch Last Modified"
                              type="date"
                              defaultValue={moment(get(row, 'updatedAt', '')).format('yyyy-MM-DD')}
                              onChange={() => {
                                console.log('changing date');
                              }}
                              sx={{ width: 220 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          }
                        </TableCell>
                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              onClick={() => handleSubjectEdit(row)}
                              style={{ color: '#880ED4', backgroundColor: 'white' }}
                            >
                              <Edit style={{ color: '#880ED4', marginRight: 5 }} /> EDIT
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={subjectCount}
            rowsPerPage={rowsPerPageSub}
            page={pageSub}
            onPageChange={handleChangePageSubject}
            onRowsPerPageChange={handleChangeRowsPerPageSubject}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>

        {/* BATCH MODAL*/}
        <div>
          <Dialog open={batchModal} onClose={() => setBatchModal(false)}>
            <DialogTitle>Batch Info</DialogTitle>
            <DialogContent>
              <TextField
                name="batchName"
                label="Batch Name"
                style={{ width: '100%', marginTop: 20 }}
                value={get(batchData, 'batchName', '')}
                onChange={(e) => {
                  handleBatchDataChange('batchName', e);
                }}
                required
              />

              <FormControl fullWidth required style={{ width: '100%', marginTop: 20 }}>
                <InputLabel id="sessiom-label">Branch</InputLabel>
                <Select
                  // labelId="session"
                  id="session-label"
                  value={get(batchData, 'branchId', '')}
                  label="Branch"
                  onChange={(e) => {
                    handleBatchDataChange('branchId', e);
                  }}
                >
                  {branchList.map((branch) => (
                    <MenuItem key={branch.branchName} value={branch._id}>
                      {branch.branchName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>

            <DialogActions>
              <Button variant="contained" onClick={insertBatch} style={{ color: 'white', backgroundColor: 'blue' }}>
                ADD / EDIT
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {/* BRANCH MODAL */}
        <div>
          <Dialog open={branchModal} onClose={() => setBranchModal(false)}>
            <DialogTitle>Branch Info</DialogTitle>
            <DialogContent>
              <TextField
                name="branchName"
                label="Branch Name"
                style={{ width: '100%', marginTop: 20 }}
                value={get(branchData, 'branchName', '')}
                onChange={(e) => {
                  handleBranchDataChange('branchName', e);
                }}
                required
              />

              <TextField
                name="email"
                label="Email"
                style={{ width: '100%', marginTop: 20 }}
                value={get(branchData, 'email', '')}
                onChange={(e) => {
                  handleBranchDataChange('email', e);
                }}
                required
              />

              <TextField
                name="mobile"
                label="Mobile Number"
                style={{ width: '100%', marginTop: 20 }}
                value={get(branchData, 'mobile', '')}
                onChange={(e) => {
                  handleBranchDataChange('mobile', e);
                }}
                required
              />

              <TextField
                name="address"
                label="Address"
                style={{ width: '100%', marginTop: 20 }}
                value={get(branchData, 'address', '')}
                onChange={(e) => {
                  handleBranchDataChange('address', e);
                }}
                required
              />
            </DialogContent>

            <DialogActions>
              <Button variant="contained" onClick={insertBranch} style={{ color: 'white', backgroundColor: 'blue' }}>
                ADD / EDIT
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {/* SUBJECT MODAL*/}
        <div>
          <Dialog open={subjectModal} onClose={() => setSubjectModal(false)}>
            <DialogTitle>Add new Subject</DialogTitle>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              {' '}
            </Stack>
            <DialogContent>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  name="name"
                  label="Subject Name"
                  style={{ width: '100%' }}
                  value={get(subjectData, 'name', '')}
                  onChange={(e) => {
                    handleSubjectDataChange('name', e);
                  }}
                  required
                />

                <TextField
                  name="subjectCode"
                  label="Subject Code"
                  style={{ width: '100%' }}
                  value={get(subjectData, 'subjectCode', '')}
                  onChange={(e) => {
                    handleSubjectDataChange('subjectCode', e);
                  }}
                  required
                />
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button variant="contained" onClick={insetSubject} style={{ color: 'white', backgroundColor: 'blue' }}>
                ADD / EDIT
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </Page>
  );
}
